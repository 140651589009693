<template>
  <v-card color="white" relative class="shadow-none rounded-0">
    <v-container class="py-7">
      <v-row align="center" justify="space-between">
        <v-col cols="12" lg="3">
          <v-row align="center" justify="space-between">

            <v-col cols="auto" class="pa-0 px-3 px-md-0 d-flex align-center d-lg-none">
              <v-btn icon class="ms-2 light" fab @click="toggleSidebar" small><img src="@/assets/img/svg/menu.svg"
                  alt="pixipine" /></v-btn>
            </v-col>

            <v-col cols="auto" class="py-0 mt-md-2">
              <router-link :to="`/${$i18n.locale}`">
                <img loading="lazy" src="@/assets/img/png/logo.png" id="logo" alt="pixipine" />
                <img loading="lazy" src="@/assets/img/png/icon.png" id="icon" alt="pixipine" />
              </router-link>
            </v-col>
            <v-col cols="auto" class="pa-0 px-3 px-md-0 d-flex align-center d-lg-none">
              <v-btn depressed color="primary" :to="`/${$i18n.locale}/registration`">
                {{ $t('signup') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" md="4" lg="5" class="d-none d-lg-block">
          <SearchInput />
        </v-col>
        <v-col cols="4" md="5" lg="3" class="d-none d-lg-block">
          <v-row justify="end">
            <v-col cols="10" md="auto" class="d-flex align-center justify-space-between">
              <CartMenu />
            </v-col>
            <v-col cols="auto" class="d-none d-lg-block pa-2" v-if="$store.getters.isAuth">
              <v-divider vertical height="38px" />
            </v-col>
            <v-col cols="2" lg="5" class="px-0" v-if="$store.getters.isAuth">
              <v-btn plain color="transparent" depressed class="font-weight-bold" :to="`/${$i18n.locale}/registration`">
                <img loading="lazy" src="@/assets/img/svg/user.svg" height="55px" class="me-2" alt="Pixipine" />
                <span class="d-none d-lg-block">
                  {{ $store.getters.user.name }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="mt-2 mt-md-0 opacity-50" />
  </v-card>
</template>

<script>


import CartMenu from "./CartMenu.vue";
import SearchInput from "./SearchInput.vue";

export default {
  name: "MiddleBar",
  components: { CartMenu, SearchInput },
  data: () => ({
    value: null,
  }),
  watch: {
    search(val) {
      if (this.search) {
        setTimeout(() => {
          if (this.search === val) {
            this.fetchData(val)
            console.log("🚀 ~ file: MiddleBar.vue:83 ~ setTimeout ~ val:", val)
          }
        }, 1000);
      } else {
        this.items = [];
      }
    },
    select() {
      console.log('select', this.select);
      if (this.select) {
        this.showItem(this.select)
      }
    },
  },
  mounted() { },
  methods: {
    changeLang() {
      // this.$i18n.locale =  this.$i18n.locale === 'en' ? 'ar' : 'en';
      this.$router.push(
        this.$route.path.replace(
          this.$i18n.locale,
          this.$i18n.locale === "en" ? "ar" : "en"
        )
      );
    },
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
  },
};
</script>
<style scoped>
#logo {
  height: 60px;

  @media screen and (max-width: 600px) {
    height: 45px;
  }

  @media screen and (max-width: 374px) {
    display: none;
  }
}

#icon {
  height: 45px;
  display: block;

  @media screen and (min-width: 374px) {
    display: none;
  }
}</style>
