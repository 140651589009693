<template>
  <section class="w-100" id="hero_section">
    <v-carousel 
     v-if="slides.length" hide-delimiters height="auto" :show-arrows="false" v-model="currentSlide"
      :interval="3000" eager>
      <v-carousel-item v-for="(slide, i) in slides" :key="i" class="pa-0" :src="$store.state.endpointURL + slide.image">
        <v-container class="h-100">
          <v-card height="100%" flat class="transparent pa-0 d-flex align-center">
            <v-row :justify="i % 2 ? 'end' : 'start'">
              <v-col cols="12" md="6">
                <h4 class="text-h6 text-md-5 text-lg-h4 mb-3" :class="i % 2 ? 'text-end' : 'text-start'">
                  {{ slide.title }}</h4>
                <h3 class="text-h5 text-md-4 text-lg-h3 font-weight-bold mt-3" :class="i % 2 ? 'text-end' : 'text-start'"
                  v-html="slide.description" />
                <!-- <v-btn class="rounded-0 mt-9 pt-2 font-weight-bold" x-large color="primary" :to="`${$i18n.locale}/products`"
                  width="140px" :class="i % 2 ? 'd-block mi-start-auto' : 'd-block'" depressed>
                  {{ $t('shop now')}}</v-btn> -->
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-carousel-item>
    </v-carousel>

    <div class="slider_arrows" v-if="slides.length > 1">
      <div>
        <v-btn icon fab small @click="nextSlide" color="gray2" class="gray2 rounded-lg">
          <v-icon color="white">{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="slider_arrows" v-if="slides.length > 1">
      <div>
        <v-btn icon fab small @click="prevSlide" color="gray2" class="gray2 rounded-lg">
          <v-icon color="white">{{ $i18n.locale == 'en' ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- <div class="slider_dots" v-if="slides.length > 1">
      <div :class="{ 'active_dot': currentSlide === index }" @click="currentSlide = index"
        v-for="(slide, index) in slides" :key="index" />
    </div> -->
    <v-skeleton-loader class="mx-auto" v-if="slides.length == 0" width="100%"
      style="position: absolute;top:180px;height:680px; transform:scale(1.65);" type="image" />
  </section>
</template>

<script>

export default {
  name: "HeroSection",
  components: {},
  props: {
    slides: { default: [] },
  },
  data: () => ({
    currentSlide: 0,
  }),
  watch: {

  },
  computed: {

  },
  mounted() {

  },

  methods: {
    nextSlide() {
      if (this.currentSlide === this.slides.length - 1) {
        this.currentSlide = 0;
      } else {
        this.currentSlide++
      }
    },
    prevSlide() {
      if (this.currentSlide === 0) {
        this.currentSlide = this.slides.length - 1;
      } else {
        this.currentSlide--
      }
    },
  }
}
</script>

<style lang="scss" scoped>
*::selection {
  background-color: #FFFFFF !important;
  color: var(--primary) !important;
}

#hero_section {
  position: relative;
  // height: 650px;
  margin-top: 0px;


  .hero_slide {
    border-radius: 16px;
    padding: 0;
    color: #FFFFFF;
    position: relative;
    z-index: 50;
    overflow: hidden;
    //cursor: grab;
    display: flex !important;
    align-items: stretch !important;

    .text-h2,
    .text-h4 {
      text-transform: capitalize;
      margin: 10px 0;
      position: relative;
      z-index: 5;

      &::selection {
        background-color: #FFFFFF !important;
        color: var(--primary) !important;
      }
    }



    @media screen and (max-width: 1260px) {
      height: calc(100vh - 300px);


      .text-h2 {
        font-size: 30px !important;
        margin: 5px 0;
      }

      .text-h4 {
        font-size: 24px !important;
        margin: 5px 0;
      }

      .slider_img {
        height: 200px;
        width: 200px;
      }
    }

    @media screen and (max-width: 991px) {
      padding: 20px 30px;
      text-align: center;
      height: calc(100vh - 200px);
      border-radius: 0px;
    }

    @media screen and (max-width: 768px) {
      height: calc(100vh - 60px);
    }
  }

  .slider_arrows {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transition: 0.3s;
    transform: translate(0%, -50%);
    width: auto;
    z-index: 1;
    padding: 0;
    opacity: 0;

    &:nth-of-type(1) {
      left: 0px;
    }

    &:nth-of-type(2) {
      right: 0px;
    }

    div {
      padding: 9px;
      border-radius: 50px;
      opacity: 0.5;
      transition: 0.3s;

      @media screen and (max-width: 991px) {
        transform: scale(0.6);
      }

      &:hover {
        opacity: 1;
      }
    }

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  .slider_dots {
    position: absolute;
    bottom: 50px;
    left: 125px;
    display: flex;

    @media screen and (max-width: 1260px) {
      left: 50%;
      transform: translate(-50%, -50%);
      bottom: 45px;
    }

    div {
      position: relative;
      width: 8px;
      height: 8px;
      margin: 0 2px;
      border-radius: 25px;
      cursor: pointer;
      background-color: #FFFFFF;
      transition: 0.5s;

      &.active_dot {
        width: 26px;
        background-color: #000000;
      }
    }
  }

  &:hover {
    .slider_arrows {
      opacity: 1;
    }
  }

}



.v-application--is-rtl {
  .slider_dots {
    left: unset;
    right: 125px;
  }

  .slider_arrows {
    &:nth-of-type(1) {
      left: unset;
      right: 0px;
    }

    &:nth-of-type(2) {
      left: 0 !important;
      right: unset !important;
    }
  }
}
</style>