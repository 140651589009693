<template>
  <v-row align="center">
    <v-col cols="auto" class="pa-2">
      <v-icon size="30" color="#0766ff" class="cursor_pointer" @click="facebook">mdi-facebook</v-icon>
    </v-col>
    <v-col cols="auto" class="pa-2">
      <v-icon size="30" color="#1c9cef" class="cursor_pointer" @click="twitter">mdi-twitter</v-icon>
    </v-col>
    <v-col cols="auto" class="pa-2">
      <v-icon size="30" color="#0eba19" class="cursor_pointer" @click="whatsapp">mdi-whatsapp</v-icon>
    </v-col>
    <v-col cols="auto" class="pa-2">
      <v-icon size="30" color="#c435b0" class="cursor_pointer" @click="instagram">mdi-instagram</v-icon>
    </v-col>

  </v-row>
</template>


<script>
export default {
  name: "SocialShare",
  data: () => ({}),
  props: {
    pageLink: { default: process.env.WEBSITE_URL },
    shareObject: { default: Object }
  },
  computed: {
    website() {
      return process.env.WEBSITE_NAME;
    }
  },
  methods: {
    facebook() {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.pageLink}`, '_blank')
    },
    twitter() {
      var shareURL = "http://twitter.com/share?";
      var params = {
        url: this.pageLink,
        text: this.shareObject.title || this.shareObject.name,
        via: "Pixipinenet",
        hashtags: this.shareObject.keywords || this.website
      }
      for (var prop in params) shareURL += '&' + prop + '=' + encodeURIComponent(params[prop]);
      window.open(shareURL, '_blank');
    },
    whatsapp() {
      console.log('this.pageLink', this.pageLink);
      window.open(`whatsapp://send?text=${this.pageLink}`, '_blank')
    },
    instagram() {
      window.open(`https://www.instagram.com/?url=${this.pageLink}`, '_blank')
    },
  }
};
</script>
