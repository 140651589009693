<template>
  <v-card class="product_card" :ripple="false" :width="width" :style="{ margin: cardMargin }" :class="'shadow-none'">


    <router-link :to="cardClicked(product)">
      <div class="product_img" :style="{ height: imgHeight }">
        <img loading="lazy" :title="product.name" :src="$store.state.endpointURL + product.image" :alt="product.name" />

        <div class="actions">
          <v-btn @click.prevent="toggleFromCart" :outlined="!in_user_cart" depressed
            :color="in_user_cart ? 'primary' : 'gray6'" class="white" fab x-small :icon="!in_user_cart">
            <v-icon> {{ in_user_cart ? ' mdi-cart-remove' : 'mdi-cart' }}</v-icon>
          </v-btn>
          <v-btn class="white" @click.prevent="toggleFromWishlist" :outlined="!in_user_wishlist" depressed
          :color="in_user_wishlist ? 'error' : 'gray6'" fab x-small :icon="!in_user_wishlist">
            <v-icon>mdi-heart</v-icon>
          </v-btn>
          <v-btn :to="cardClicked(product)" class="white" fab x-small icon>
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </div>
      </div>
    </router-link>

    <div class="product_description">
      <h6 class="text-h6">{{ product.name }}</h6>
      <v-rating length="5" size="15" color="warning" class="d-block ma-auto" :value="product.rate" v-if="product.rate" />
      <h6 class="subtitle-1 primary--text">{{ product.price | float }} {{ $t('le') }}</h6>
    </div>

  </v-card>
</template>

<script>
export default {
  name: "ProductCardTwo",
  data: () => ({
    quantity: 0,
    isLoading: false,
  }),
  props: {
    product: { default: Object },
    width: { default: 'auto' },
    imgHeight: { default: '275px' },
    cardMargin: { default: null },
    cardClicked: { default: Function },
  },
  watch: {},
  mounted() {

  },
  computed: {
    in_user_cart() {
      let id = +this.product.product_id || +this.product.id
      let foundInCart = this.$store.getters.getAllCart.products.some((product) => product.product_id == id);
      return foundInCart
    },
    in_user_wishlist() {
      let id = +this.product.product_id || +this.product.id
      let foundInWishList = this.$store.getters.getAllWishList.some((product) => product.product_id == id);
      return foundInWishList
    },
  },
  methods: {
    toggleFromWishlist() {
      if (this.in_user_wishlist) {
        let id = +this.product.product_id || +this.product.id
        let product = { product_id: id, name: this.product.name, remove_product: true }
        this.$store.dispatch("removeFromWishlist", product);
      } else {
        const product = {
          product_id: this.product.product_id || this.product.id,
          name: this.product.name,
          brand_name: this.product.brand_name,
          image: this.product.image,
          price: this.product.price,
          quantity: 1,
        }
        this.$store.dispatch("addToWishlist", product);
      }
    },
    toggleFromCart() {
      if (this.in_user_cart) {
        let id = +this.product.product_id || +this.product.id
        let product = { product_id: id, name: this.product.name, remove_product: true }
        this.$store.dispatch("removeFromCart", product);
      } else {
        this.setCartAction(true);
      }
    },
    setCartAction() {
      const product = {
        product_id: this.product.product_id || this.product.id,
        name: this.product.name,
        brand_name: this.product.brand_name,
        image: this.product.image,
        price: this.product.price,
        quantity: this.quantity,
      }
      this.$store.dispatch("addToCart", product);
    },
    removeFromCart() {
      let product = this.product
      product.remove_product = true;
      this.$store.dispatch("removeFromCart", product);
    },
    increment() {
      this.$store.dispatch("addToCart", this.product);
    },
    decrement() {
      this.$store.dispatch("removeFromCart", this.product);
    },
  },
};
</script>

<style lang="scss" scoped>
.product_card {
  box-shadow: none;
  border-radius: 0;
  border: 0;
  height: auto;
  margin: 10px 0;

  &:focus:before {
    opacity: 0;
  }

  .text-h6 {
    height: 60px;
    overflow: hidden;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;

    //display: flex;
    @media screen and (max-width: 991px) {
      font-size: 1.1rem !important;
    }
  }

  .product_img {
    position: relative;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    z-index: 5;
    padding: 0.3rem 0.6rem;
    cursor: pointer;
    overflow: hidden;

    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      overflow-clip-margin: content-box;
      overflow: clip;
      position: relative;
      z-index: 1;

    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.05);
      pointer-events: none;
      z-index: 2;
    }

    .actions {
      position: absolute;
      content: '';
      z-index: 5;
      width: 100%;
      bottom: -60px;
      display: flex;
      justify-content: center;
      transition: 0.4s;

      .v-btn {
        margin: 5px;
      }
    }

    &:hover {
      .actions {
        bottom: 8px;
      }
    }
  }

  .product_description {
    margin-top: 10px;

    .v-rating {
      width: fit-content;
    }

    .text-h6,
    .subtitle-1 {
      text-align: center;
    }
  }
}

.v-application--is-rtl {
  .product_card {
    .product_description {}

    &:hover {}
  }

}
</style>
