import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "../store";
import i18n from "./i18n";

export default {
  install(Vue) {
    Vue.prototype.$global = {
      ///////// Input Required Rules /////////
      requiredRule: [(v) => !!v || i18n.t("Field is required")],
      ///////// Input Email Required Rules /////////
      notRequiredRule: [],
      emailRule: [
        (v) => !!(v || "").match(/@/) || i18n.t("Please enter email address"),
      ],
      ///////// Input Mobile Required Rules /////////
      mobileRule: [
        (v) =>
          !!(v || "").match(/^(?:[+0]9)?[0-9]{10,12}$/) ||
          i18n.t("Please enter mobile number"),
      ],
      ///////// Input Password Required Rules /////////
      passwordRule: [
        (v) => !!v || i18n.t("Password is required"),
        (v) =>
          (v && v.length >= 5) ||
          i18n.t("Password must be greater than 5 characters"),
      ],

      /////////  Array /////////
      FilterPermissions(Screen_Code) {
        if (store.state.permissions.length > 0) {
          var find = store.state.permissions.filter(
            (obj) => obj["screen_code"] === Screen_Code
          );
          return find[0];
        }
      },
      CheckAction(Screen_Code, Action) {
        var find = store.state.permissions.filter(
          (obj) =>
            obj["screen_code"] === Screen_Code &&
            (obj["permission"].includes(Action.toString()) ||
              obj["permission"].includes(Number(+Action)))
        );
        return find.length > 0 ? true : false;
      },
      FilterArray(ARRAY, filterBy) {
        return ARRAY.filter((find) => find === filterBy);
      },
      FilterArrayOfObject(ARRAY, SearchIn, filterBy) {
        return ARRAY.filter((obj) => obj[SearchIn] === filterBy);
      },
      FilterArrayOfObjectElement(ARRAY, SearchIn, filterBy, ReturnElement = null) {
        const filter = ARRAY.filter((obj) => obj[SearchIn] === filterBy);
        return filter[0][ReturnElement];
      },
      FilterObjectByArray(OBJECT, ARRAY) {
        return ARRAY.reduce((obj, key) => ({ ...obj, [key]: OBJECT[key] }), {});
      },
      RemoveFromArray(ARRAY, SearchIn, VALUE) {
        const removeIndex = ARRAY.findIndex((find) => find[SearchIn] === VALUE);
        return ARRAY.splice(removeIndex, 1);
      },
      /////////   Calculate pagenation  /////////
      CalculateNumberOfPages(TOTAL, LIMIT) {
        return Math.ceil(TOTAL / LIMIT);
      },
      /////////  Numbers /////////
      NumberWithSpaces(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      ConvertToPercentage(PartialValue, TotalValue) {
        return ((100 * PartialValue) / TotalValue).toFixed(2);
      },
      ConvertFromPercentage(Percent, Total) {
        return ((Percent / 100) * Total).toFixed(2);
      },
      CalculatePriceTax(Price, Tax) {
        return {
          price: Number(+Price),
          tax: Number(+Tax),
          priceAfterTax: Number(+Price) + Number(+Price) * Number(+Tax),
          pricePercentage: 100 - 100 * Number(+Tax),
          taxPercentage: 100 * Number(+Tax),
        };
      },
      RyalFormatter(NUMBER) {
        var formatter = new Intl.NumberFormat("ar", {
          style: "currency",
          currency: "SAR",
        });

        return formatter.format(NUMBER);
      },
      FILE_TYPE(FILE) {
        // Base64
        console.log(FILE);
        if (String(FILE).substring(0, 5) == "data:") {
          console.log(
            "FILE TYPE =>",
            FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
          );
          switch (FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "excel";
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            case "video/mp4":
              return "mp4";
            case "video/ogg":
              return "mp4";
            case "video/quicktime":
              return "quicktime";
            case "video/mov":
              return "mov";
            default:
              return "notFound";
          }
        }
        // Normal
        else {
          switch (FILE.type) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "excel";
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            case "video/mp4":
              return "mp4";
            case "video/ogg":
              return "mp4";
            case "video/quicktime":
              return "quicktime";
            case "video/mov":
              return "mov";
            default:
              var n = FILE.lastIndexOf(".");
              var result = FILE.substring(n + 1);
              return result;
          }
        }
      },
      Currency(NUMBER) {
        return NUMBER.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      },
      NumberWithCommas(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      IncrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            find[ATTRIBUTE] = find[ATTRIBUTE] + 1;
          }
        });
      },
      DecrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            if (find[ATTRIBUTE] > 1) {
              find[ATTRIBUTE] = find[ATTRIBUTE] - 1;
            }
          }
        });
      },
      DecimalFormatter(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      ///////// Decryption / Encryption /////////
      CRYPT(KEY, TEXT) {
        // 1
        let textEncrypt = Vue.prototype.$CryptoJS.enc.Base64.stringify(
          Vue.prototype.$CryptoJS.enc.Utf8.parse(TEXT)
        );

        return textEncrypt

        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(KEY);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var encrypted = Vue.prototype.$CryptoJS.AES.encrypt(TEXT, x, {iv: iv});
        // return encrypted.toString();

      },
      DECRYPT(KEY, ENCODED) {
        // 1
        let textDecrypt = Vue.prototype.$CryptoJS.enc.Base64.parse(ENCODED).toString(
          Vue.prototype.$CryptoJS.enc.Utf8
        );
        return textDecrypt
        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(key);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var decrypted = Vue.prototype.$CryptoJS.AES.decrypt(encryptedString, x,{iv:iv});
        // return decrypted.toString();

      },
      SelectAsFromArray(original_array = [], required_object = Object) {
        let filtered_array = [];
        original_array.forEach((object) => {
          let new_object = Object;
          Object.keys(required_object).forEach((key) => {
            if (object[required_object[key]])
              new_object[key] = object[required_object[key]];
          });
          filtered_array.push({ ...new_object });
        });
        return filtered_array || [];
      },
      SelectFromObject() {
        let original_object = arguments.length ? arguments[0] : Object;
        let required_keys =
          arguments.length > 1 ? Object.keys(arguments).slice(1) : [];
        let filtered_object = required_keys.reduce(
          (prevKey, nextKey) =>
            original_object[arguments[nextKey]]
              ? {
                ...prevKey,
                [arguments[nextKey]]: original_object[arguments[nextKey]],
              }
              : { ...prevKey },
          {}
        );
        return filtered_object;
      },
      RemovesNullParams(PARAMS) {
        console.log('PARAMS', PARAMS);
        return PARAMS.toString().replace(/\w+=&/g, '').replace(/&\w+=$/, '')
      },
      RouteParam(TEXT) {
        let converted = TEXT.split(/\s+/).join('-').replace('%', '').replace('&', '').toLowerCase();
        return converted
      },
      // Dates
      GetCurrentTime() {
        var time = new Date();
        return time.getHours() + ":" + time.getMinutes();
      },

      GetCurrentDate() {
        var d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        return [year, month, day].join('-');
      },

      GetFirstDayOfMonth() {
        var d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        const firstDay = new Date(d.getFullYear(), d.getMonth(), 2);
        return firstDay.toISOString().split('T')[0];
      },
      SubtractDates(FIRST_DATE, SECOND_DATE) {
        // const dateOne = new Date(FIRST_DATE); 
        // const dateTwo = new Date(SECOND_DATE);
        // return dateTwo.getDate() - dateOne.getDate();
        var dateFirst = new Date(FIRST_DATE.replace("-", "/"));
        var dateSecond = new Date(SECOND_DATE.replace("-", "/"));
        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
        return Math.ceil(timeDiff / (1000 * 3600 * 24));

      },
      SubtractFromDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() - Number(VALUE));
        return date.toISOString().split('T')[0];
      },
      AddToDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() + Number(VALUE));
        return date.toISOString().split('T')[0];
      },
      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, TOAST = true, MSG, TIMER = 3000) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: TOAST,
          timer: TIMER,
          position:
            TOAST == true
              ? store.state.Settings.translation.rtl == true
                ? "top-start"
                : "top-end"
              : "center",
          showConfirmButton: false,
        });
      },

      readFileAsBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(event.target.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
      },
      // Cookies
      async SET_COOKIE(COOKIE_NAME, VALUE, EXPIRE = 365) {
        navigator.cookieEnabled;
        this.DELETE_COOKIE(COOKIE_NAME)
        console.log('ss', VALUE);
        try {
          let date = new Date(this.AddToDate(new Date(), EXPIRE));
          date.setTime(date.getTime() + 1 * 60 * 60 * 1000);
          var cache_value =
            escape(VALUE) +
            `; expires=${date.toUTCString()}; SameSite=Lax; Secure` +
            ("; path=/");
          document.cookie = COOKIE_NAME + "=" + cache_value;
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      async GET_COOKIE(NAME) {
        try {
          var i,
            x,
            y,
            ARRcookies = document.cookie.split(";");
          for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == NAME) {
              return unescape(y);
            }
          }
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      async DELETE_COOKIE(NAME) {
        try {
          document.cookie = `${NAME}=;expires=${new Date(0).toUTCString()}` + `domain=' + d.join('.') + ' ;path=`;
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      async DELETE_ALL_COOKIES() {
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split(".");
          while (d.length > 0) {
            var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            var p = location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
              document.cookie = cookieBase + p.join('/');
              p.pop();
            }
            d.shift();
          }
        }
      },
      // Browser Permissions
      async IS_POPUP_BLOCKED() {
        var newWindow = window.open(null, '');
        try {
          newWindow.close();
          return false;
        } catch (error) {
          return true;
        }
      },
    };
  },
};
