<template>
  <section>
    <!-- breadcrumb -->
    <v-card flat color="light" v-if="product.id && !isLoading">
      <v-container>
        <h3 class="text-h6 font-weight-bold text-uppercase">{{ product.name }}</h3>
        <h6 class="subtitle-2 text-capitalize">
          {{ $t('home') }}
          <v-icon size="20">{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
          {{ product.brand_name }}
          <v-icon size="20">{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
          {{ product.name }}
        </h6>
      </v-container>
    </v-card>
    <!-- Product -->
    <v-container class="mt-2" v-if="product.id && !isLoading">
      <v-row>
        <!-- Product Image -->
        <v-col cols="12" md="6" lg="4">
          <v-card class="rounded-0" outlined>
            <img class="d-block ma-auto" style="max-width : 360px" :src="$store.state.endpointURL + product.image"
              height="300px" :alt="product.name" :title="product.name">
          </v-card>
        </v-col>
        <!-- Product Details -->
        <v-col cols="12" md="6" class="d-flex flex-column justify-space-between">
          <div>
            <h4 class="text-h5 font-weight-bold">{{ product.name }}</h4>
            <h6 class="subtitle-1 gray6--text font-weight-bold">
              <router-link class="text-decoration-none gray6--text"
                :to="`/${$i18n.locale}/products?brand=${product.brand_id}`">{{ product.brand_name }}</router-link>
            </h6>
            <h6 class="subtitle-1 gray6--text font-weight-bold">
              {{ $t('Availability') }} : <span
                :class="product.available_status_id == 1 ? 'green1--text' : 'error--text'">{{
                  product.available_status_name }}</span>
            </h6>
            <v-divider class="my-3" />
            <h4 class="text-h4 font-weight-bold">{{ product.price | float }} {{ $t('le') }}</h4>
            <h4 class="body-2 my-4 gray6--text font-weight-bold" v-html="product.short_des" />
          </div>
          <div>
            <div class="d-flex align-center">
              <v-btn block @click="toggleFromCart" class="text-uppercase font-weight-bold subtitle-1 my-2 rounded-0 mb-5"
                :color="in_user_cart ? 'error' : 'primary'" height="50" large depressed>
                {{ in_user_cart ? $t('remove from cart') : $t('add to cart') }}
              </v-btn>
              <v-btn  @click="toggleFromWishlist" :text="!in_user_wishlist" depressed
                :color="in_user_wishlist ? 'error' : 'gray6'" fab class="ms-2 mt-0 mb-2" height="50" width="50">
                <v-icon>mdi-heart</v-icon>
              </v-btn>
            </div>
            <SocialShare :pageLink="pageLink" :shareObject="product" />
          </div>
        </v-col>
        <!-- Product Tabs -->
        <v-col cols="12">
          <v-tabs v-if="product.description || product.specifications || product.includes" v-model="activeTab"
            color="primary">
            <v-tab class="text-capitalize" active-class="light" v-if="product.description">
              {{ $t('description') }}
            </v-tab>
            <v-tab class="text-capitalize" active-class="light" v-if="product.specifications">
              {{ $t('specifications') }}</v-tab>
            <v-tab class="text-capitalize" active-class="light" v-if="product.includes">{{ $t('includes') }}</v-tab>
          </v-tabs>
          <v-card outlined class="pa-0 rounded-0"
            v-if="product.description || product.specifications || product.includes">
            <v-tabs v-model="activeTab" color="primary">
              <v-tab-item class="pa-5 body-2 font-weight-bold" v-if="product.description">
                <div v-html="product.description" />
              </v-tab-item>
              <v-tab-item class="pa-5 body-2 font-weight-bold" v-if="product.specifications">
                <div v-html="product.specifications" />
              </v-tab-item>
              <v-tab-item class="pa-5 body-2 font-weight-bold" v-if="product.includes">
                <div v-html="product.includes" />
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
        <!-- Related products -->
        <v-col cols="12" v-if="related_products?.length">
          <RowHeader :title="'related products'" />
          <v-row class="mt-3">
            <v-slide-group show-arrows>
              <v-slide-item v-for="(relatedProduct, index) in related_products" :key="index">
                <ProductCard :width="`250px`" cardMargin="10px" :product="relatedProduct" :cardClicked="showProduct" />
              </v-slide-item>
            </v-slide-group>
          </v-row>
        </v-col>
        <!-- Back Btn -->
        <v-col cols="12" class="justify-center d-flex">
          <v-btn text large :to="`/${$i18n.locale}/products`">
            <v-icon left>{{ $i18n.locale == 'en' ? 'mdi-chevron-left' : 'mdi-chevron-right' }} </v-icon>
            {{ $t('back to') }} {{ $t('products') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!-- loader -->
    <v-col cols="12" class="d-flex justify-center my-16" v-if="isLoading">
      <v-progress-circular indeterminate :rotate="360" :size="100" :width="15" color="primary" />
    </v-col>

  </section>
</template>


<script>
import SocialShare from '@/components/ui/SocialShare.vue';
import RowHeader from '@/components/ui/RowHeader.vue';
import ProductCard from '@/components/ui/ProductCard.vue';
export default {
  name: "ProductView",
  components: { SocialShare, RowHeader, ProductCard },
  data: () => ({
    isLoading: false,
    product: {},
    pageLink: null,
    related_products: [],
    activeTab: 0,
    quantity: 0,
  }),
  watch: {
    $route() {
      this.getProduct();
    },
  },
  created() {

  },
  destroyed() {

  },
  computed: {
    in_user_cart() {
      let foundInCart = this.$store.getters.getAllCart.products.some((product) => product.product_id == +this.product.id);
      return foundInCart
    },
    in_user_wishlist() {
      let id = +this.product.id
      let foundInWishList = this.$store.getters.getAllWishList.some((product) => product.product_id == id);
      return foundInWishList
    },
  },
  mounted() {
    this.pageLink = this.$store.state.WEBSITE_URL + this.$route.fullPath;
    this.getProduct();
  },
  methods: {
    getProduct() {
      this.isLoading = true;
      this.$api.GET_METHOD(`show_product/${this.$router.currentRoute.query.id}`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.product = response.data.data || {};
          this.related_products = response.data.related_products || [];
        }
      })
    },
    showProduct({ brand_name, name, id }) {
      let route = this.$global.RouteParam(`/${this.$i18n.locale}/products/${brand_name ? brand_name : 'brand'}/${name.replaceAll('/', '-')}?id=${id}`)
      return route;
    },
    toggleFromWishlist() {
      if (this.in_user_wishlist) {
        let id = +this.product.id
        let product = { product_id: id, name: this.product.name, remove_product: true }
        this.$store.dispatch("removeFromWishlist", product);
      } else {
        const product = {
          product_id: this.product.id,
          name: this.product.name,
          brand_name: this.product.brand_name,
          image: this.product.image,
          price: this.product.price,
          quantity: 1,
        }
        this.$store.dispatch("addToWishlist", product);
      }
    },
    toggleFromCart() {
      if (this.in_user_cart) {
        let product = { product_id: this.product.id, name: this.product.name, remove_product: true }
        this.$store.dispatch("removeFromCart", product);
      } else {
        this.setCartAction(true);
      }
    },
    setCartAction() {
      const product = {
        product_id: this.product.id,
        name: this.product.name,
        brand_name: this.product.brand_name,
        image: this.product.image,
        price: this.product.price,
        quantity: this.quantity,
      }
      this.$store.dispatch("addToCart", product);
    },
  }

};
</script>


<style lang="scss" scoped></style>