<template>
  <section>
    <!-- breadcrumb -->
    <v-card flat color="light" class="rounded-0">
      <v-container>
        <h3 class="text-h6 font-weight-bold text-uppercase">{{ $t('wishlist') }}</h3>
        <h6 class="subtitle-2 text-capitalize">
          {{ $t('home') }}
          <v-icon size="20">{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
          {{ $t('wishlist') }}
        </h6>
      </v-container>
    </v-card>

    <v-container >
      <!-- list view -->

      <v-row align="center"  class="mt-2" v-if="wishList.length">
        <v-col cols="6" sm="4" lg="3" v-for="(product, index) in wishList" :key="index">
          <ProductCard :width="`250px`" cardMargin="0" :product="product" :cardClicked="showProduct" />
        </v-col>
      </v-row>

      <v-col cols="12" class="pt-0" v-else>
        <v-card flat height="30vh">
          <v-alert outlined color="gray9" class="shadow rounded-0">
            <div class="gray4--text font-weight-bold text-center"> {{ $t('no products here in wishlist') }}</div>
          </v-alert>
        </v-card>
      </v-col>


    </v-container>

  </section>
</template>


<script>
import ProductCard from '@/components/ui/ProductCard.vue';


export default {
  name: "Favorites",
  components: { ProductCard },
  data: () => ({}),
  watch: {

  },
  created() {

  },
  destroyed() {

  },
  computed: {
    wishList() {
      return this.$store.getters.getAllWishList
    }
  },
  mounted() {

  },
  methods: {
    showProduct({ brand_name, name,id, product_id }) {
      let route = this.$global.RouteParam(`/${this.$i18n.locale}/products/${brand_name ? brand_name : 'brand'}/${name.replaceAll('/', '-')}?id=${id || product_id}`)
      return route;
    },
  }

};
</script>


<style lang="scss" scoped></style>