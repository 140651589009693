<template>
  <section>
    <!-- breadcrumb -->
    <v-card flat color="light" class="rounded-0">
      <v-container>
        <h3 class="text-h6 font-weight-bold text-uppercase">{{ $t('contact us') }}</h3>
        <h6 class="subtitle-2 text-capitalize">
          {{ $t('home') }}
          <v-icon size="20">{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
          {{ $t('contact us') }}
        </h6>
      </v-container>
    </v-card>
    <v-container class="my-2">
      <v-row>
        <v-col cols="12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13821.864444852587!2d31.342725649999995!3d29.994769800000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2seg!4v1699737733773!5m2!1sen!2seg"
            width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </v-col>
        <v-col cols="12" md="6">
          <div v-html="$store.state.basic_data.contact" />
        </v-col>
        <v-col cols="12" md="6">
          <v-row justify="end">
            <GenericInput type="text" :value="form.name" @input="form.name = $event" label="name" :required="true"
              :isLoading="false" :cols="[12, 6, 6]" />
            <GenericInput type="email" :value="form.email" @input="form.email = $event" label="email" :required="true"
              :isLoading="false" :cols="[12, 6, 6]" />
            <GenericInput type="mobile" :value="form.mobile" @input="form.mobile = $event" label="mobile" :required="true"
              :isLoading="false" :cols="[12, 12, 12]" />
            <GenericInput type="textarea" :value="form.message" @input="form.message = $event" label="message"
              :required="true" :isLoading="false" :cols="[12, 12, 12]" />
            <v-col cols="auto">
              <v-btn class="text-uppercase rounded-0 subtitle-1" depressed large color="primary" :loading="isLoading"
                @click="sendContact">
                {{ $t('send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>


<script>
export default {
  name: "AboutUs",
  components: {
  },
  mounted() {

  },
  data: () => ({
    form: {
      name: null,
      mobile: null,
      email: null,
      message: null,
    },
    isLoading: false,
  }),
  methods: {
    sendContact() {
      if (this.form.name && this.form.mobile) {
        this.isLoading = true;
        this.$api.POST_METHOD(`contact_us`, this.form).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.$api.ShowModal('success', response.msg);
            this.form = {
              name: null,
              mobile: null,
              email: null,
              message: null,
            }
          } else {
            this.$api.ShowModal('error', response.msg);
          }
        })
      } else {
        this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
      }
    }
  },

}
</script>
<style scoped></style>