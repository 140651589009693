// Website Module Routes:-
import HomeModule from "@/views/Website/Home/HomeModule";
import ProductsList from "@/views/Website/Products/ProductsList";
import ProductView from "@/views/Website/Products/ProductView";
import AboutUs from "@/views/Website/AboutUs";
import ContactUs from "@/views/Website/ContactUs";
import Favorites from "@/views/Website/Favorites";
import Cart from "@/views/Website/Cart";
import Checkout from "@/views/Website/Checkout";
import RegisterFormPage from "@/views/Website/RegisterFormPage";
import AuthenticationPage from "@/views/Website/AuthenticationPage.vue";
import ProfilePage from "@/views/Website/Profile/ProfilePage.vue";
import UserPage from "@/views/Website/Profile/UserPage.vue";
import OrderPage from "@/views/Website/Profile/OrderPage.vue";


export const WebsiteModuleRoutes = [
  {
    path: "/",
    name: "HomeModule",
    component: HomeModule,
    meta: {
      title: "home",
    },
  },
  // Favorites
  {
    path: "/wishlist",
    name: "Favorites",
    component: Favorites,
    meta: {
      title: "favorites",
    },
  },
  {
    path: "/en/wishlist",
    name: "FavoritesEN",
    component: Favorites,
    meta: {
      title: "favorites",
    },
  },
  {
    path: "/ar/wishlist",
    name: "FavoritesAR",
    component: Favorites,
    meta: {
      title: "favorites",
    },
  },
  // Cart
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    meta: {
      title: "cart",
    },
  },
  {
    path: "/en/cart",
    name: "CartEN",
    component: Cart,
    meta: {
      title: "cart",
    },
  },
  {
    path: "/ar/cart",
    name: "CartAR",
    component: Cart,
    meta: {
      title: "cart",
    },
  },
  // Checkout
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: {
      title: "checkout",
    },
  },
  {
    path: "/en/Checkout",
    name: "CheckoutEN",
    component: Checkout,
    meta: {
      title: "checkout",
    },
  },
  {
    path: "/ar/Checkout",
    name: "CheckoutAR",
    component: Checkout,
    meta: {
      title: "checkout",
    },
  },
  // Registration
  {
    path: "/registration",
    name: "RegisterFormPage",
    component: RegisterFormPage,
    meta: {
      title: "registration",
    },
  },
  {
    path: "/en/registration",
    name: "RegisterFormPageEN",
    component: RegisterFormPage,
    meta: {
      title: "registration",
    },
  },
  {
    path: "/ar/registration",
    name: "RegisterFormPageAR",
    component: RegisterFormPage,
    meta: {
      title: "registration",
    },
  },
  // About
  {
    path: "/about",
    name: "AboutUs",
    component: AboutUs,
    meta: {
      title: "about",
    },
  },
  {
    path: "/en/about",
    name: "AboutUsEN",
    component: AboutUs,
    meta: {
      title: "about",
    },
  },
  {
    path: "/ar/about",
    name: "AboutUsAR",
    component: AboutUs,
    meta: {
      title: "about",
    },
  },
  // ContactUs
  {
    path: "/contact",
    name: "ContactUs",
    component: AboutUs,
    meta: {
      title: "contact",
    },
  },
  {
    path: "/en/contact",
    name: "ContactUsEN",
    component: ContactUs,
    meta: {
      title: "contact",
    },
  },
  {
    path: "/ar/contact",
    name: "ContactUsAR",
    component: ContactUs,
    meta: {
      title: "contact",
    },
  },


  // product list
  {
    path: "/products",
    name: "ProductsList",
    component: ProductsList,
    meta: {
      title: "products",
    },
  },
  {
    path: "/en/products",
    name: "ProductsListEN",
    component: ProductsList,
    meta: {
      title: "products",
    },
  },
  {
    path: "/ar/products",
    name: "ProductsListAR",
    component: ProductsList,
    meta: {
      title: "products",
    },
  },
  // product view
  {
    path: "/products/:brand/:product",
    name: "ProductView",
    component: ProductView,
    meta: {
      title: "product",
    },
  },
  {
    path: "/en/products/:brand/:product",
    name: "ProductViewEN",
    component: ProductView,
    meta: {
      title: "product",
    },
  },
  {
    path: "/ar/products/:brand/:product",
    name: "ProductViewAR",
    component: ProductView,
    meta: {
      title: "product",
    },
  },
  // Authentication
  {
    path: "/authentication",
    name: "AuthenticationPage",
    component: AuthenticationPage,
    meta: {
      title: "my account",
    },
  },
  {
    path: "/en/authentication",
    name: "AuthenticationPageEN",
    component: AuthenticationPage,
    meta: {
      title: "my account",
    },
  },
  {
    path: "/ar/authentication",
    name: "AuthenticationPageAR",
    component: AuthenticationPage,
    meta: {
      title: "my account",
    },
  },
  // profile
  {
    path: "/profile",
    name: "ProductView",
    component: ProfilePage,
    children: [
      {
        path: "/",
        name: "UserPage",
        component: UserPage,
        meta: {
          title: "my account",
        },
      },
      {
        path: "/profile/orders",
        name: "OrderPage",
        component: OrderPage,
        meta: {
          title: "my account",
        },
      },
    ],
  },
  {
    path: "/en/profile",
    name: "ProductViewEN",
    component: ProfilePage,
    children: [
      {
        path: "/",
        name: "UserPageEN",
        component: UserPage,
        meta: {
          title: "my account",
        },
      },
      {
        path: "/en/profile/orders",
        name: "OrderPageEN",
        component: OrderPage,
        meta: {
          title: "my account",
        },
      },
    ],
  },
  {
    path: "/ar/profile",
    name: "ProductViewAR",
    component: ProfilePage,
    children: [
      {
        path: "/",
        name: "UserPageAR",
        component: UserPage,
        meta: {
          title: "my account",
        },
      },
      {
        path: "/ar/profile/orders",
        name: "OrderPageAR",
        component: OrderPage,
        meta: {
          title: "my account",
        },
      },
    ],
  },
];
