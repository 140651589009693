<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <h6 class="subtitle-1">{{ $t('studio account') }}</h6>
      </v-col>

      <GenericInput type="text" :value="form.full_name" @input="form.full_name = $event" paddingY="py-2"
        label="Full Name (As in National ID)" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" />

      <GenericInput type="text" :value="form.mobile" @input="form.mobile = $event" paddingY="py-2" label="Mobile Phone"
        :required="true" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" />

      <GenericInput type="text" :value="form.whatsapp" @input="form.whatsapp = $event" paddingY="py-2" label="whatsapp"
        :required="true" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" />

      <GenericInput type="email" :value="form.email" @input="form.email = $event" paddingY="py-2" label="E-mail Address"
        :required="true" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" />


      <GenericInput type="file" :value="form.id_card_front" @input="form.id_card_front = $event" paddingY="py-3"
        :multi="false" label="Upload Your National ID (Front)" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" hint="(PNG - JPG - PDF)" />

      <GenericInput type="file" :value="form.id_card_back" @input="form.id_card_back = $event" paddingY="py-3"
        :multi="false" label="Upload Your National ID (Rear)" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" hint="(PNG - JPG - PDF)" />

      <GenericInput type="text" :value="form.profile_link" @input="form.profile_link = $event" paddingY="py-2"
        label="Facebook/ Instagram/ Linkedin In Personal Profile Link" hint="Personal Profile Facebook/ Instagram"
        :required="true" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" />

      <GenericInput type="radiogroup" paddingY="py-2" :lookups="hearingTypes" selected_label="name" selected_prop="name"
        :required="true" :value="form.hearing_type" @input="form.hearing_type = $event" label="How did you hear about us?"
        :isLoading="false" :cols="[12, 12, 12]" />

      <GenericInput type="radiogroup" paddingY="py-2" :lookups="governments" selected_label="name" selected_prop="name"
        :required="true" :value="form.government" @input="form.government = $event" label="Home Address"
        :isLoading="false" :cols="[12, 12, 12]" />

      <v-col cols="12" class="d-flex justify-end">
        <v-btn class="text-uppercase rounded-0 subtitle-1" :disabled="!(valid)" depressed large color="primary"
          :loading="isLoading" @click="sendForm">
          {{ $t('registration') }}
        </v-btn>
      </v-col>

    </v-row>
  </v-col>
</template>


<script>
export default {
  name: "StudioForm",
  props: {
    accountTypes: { default: [] },
    hearingTypes: { default: [] },
    governments: { default: [] },
    categories: { default: [] },
    cities: { default: [] },
    cameras: { default: [] },
    form: { default: {} },
    valid: { default: false },
    isLoading: { default: false },
    inputDesign: { default: 'inline_label' },
  },
  data: () => ({
    step: 1,
  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    sendForm() {
      if (this.valid) {
        this.isLoading = true;
        this.$api.POST_METHOD_MULTIPART(`register_form_post`, this.form).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.$store.state.snackbarTime = 8500;
            this.$router.push(`/${this.$i18n.locale}/`)
          } else {
            this.$api.ShowModal('error', response.msg);
          }
        })
      } else {
        this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
      }
    }
  },
};
</script>



<style scoped lang="scss"></style>
