<template>
  <vue-dropzone v-if="type == 'dropzone' && dropzoneOptions" :options="dropzoneOptions" :useCustomSlot="true"
    id="dropzone" :includeStyling="true" @vdropzone-file-added="onImageChange"
    @vdropzone-max-files-exceeded="maxFilesReached" ref="myVueDropzone">
    <div class="dropzone-custom-content">
      <v-row align="center" :justify="selectedImage && oldFile ? 'center' : 'start'">
        <v-col cols="auto" v-if="oldFile">
          <img class="pa-1" style="max-height: 120px;" v-if="oldFile" alt="" :src="endpointURL + oldFile" />
        </v-col>
        <v-col cols="auto"
          v-if="((selectedImage && !(selectedImage == value)) || (selectedImage && oldFile)) && oldFile">
          <v-icon v-if="$vuetify.rtl == true">mdi-arrow-left</v-icon>
          <v-icon v-if="$vuetify.rtl == false">mdi-arrow-right</v-icon>
        </v-col>
        <v-col cols="auto" v-if="selectedImages.length == 0">
          <img class="pa-1" style="max-height: 120px;" v-if="value && value != oldFile" alt=""
            :src="String(value).substring(0, 10) == 'data:image' ? value : endpointURL + value" />
          <img class="pa-1" height="120" v-if="!value && !icon" alt="" src="@/assets/img/svg/uploadImg.svg" />
          <img class="pa-1" height="120" v-if="selectedImages.length == 0 && multi" alt=""
            src="@/assets/img/svg/uploadImg.svg" />
        </v-col>
        <v-col cols="auto" v-for="(img, index) in selectedImages" :key="index">
          <v-hover v-slot="{ hover }">
            <div>
              <v-btn color="error" icon style="z-index: 555;" class="mx-auto position-absolute"
              @click="removeItem(index)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
    
              <img class="pa-1" :class="{ 'opacity-015': hover }" height="120"
                v-if="String(img).substring(0, 10) !== 'data:image'"
                :src="endpointURL + img" :alt="''" />

              <img class="pa-1" :class="{ 'opacity-015': hover }" height="120"
                v-if="String(img).substring(0, 10) === 'data:image' && ($global.FILE_TYPE(img) == 'jpeg' || $global.FILE_TYPE(img) == 'jpg' || $global.FILE_TYPE(img) == 'png' || $global.FILE_TYPE(img) == 'gif' || $global.FILE_TYPE(img) == 'svg')"
                :src="img" :alt="''" />

                <iframe width='300' height='200' :src="String(img).substring(0, 20) == 'data:application/pdf' ? img : endpointURL + img" v-if="$global.FILE_TYPE(img) == 'pdf'" />

                <img class="pa-1" height="120" :src="require(`@/assets/img/png/files/${$global.FILE_TYPE(img)}.png`)" :alt="''"
                v-if="String(img).substring(0, 5) === 'data:' &&  $global.FILE_TYPE(img) !== 'jpeg' && $global.FILE_TYPE(img) !== 'jpg' && $global.FILE_TYPE(img) !== 'png' && $global.FILE_TYPE(img) !== 'gif' && $global.FILE_TYPE(img) !== 'svg' && $global.FILE_TYPE(img) !== 'pdf'" />
            </div>
          </v-hover>
        </v-col>
        <v-col cols="auto"
          v-if="((selectedImage && !(selectedImage == value)) || (selectedImage && oldFile)) && !oldFile">
          <v-icon v-if="$vuetify.rtl == true">mdi-arrow-left</v-icon>
          <v-icon v-if="$vuetify.rtl == false">mdi-arrow-right</v-icon>
        </v-col>



        <v-col cols="auto">
          <iframe width='300' height='200' :src="selectedImage" v-if="selectedImage && (fileType == 'pdf')" />

          <img
            v-if="selectedImage && (fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'png' && fileType !== 'gif' && fileType !== 'svg' && fileType !== 'pdf')"
            :src="require(`@/assets/img/png/files/${fileType}.png`)" height="120" alt="pixipine" />
        </v-col>

        <v-col cols="auto" v-if="selectedImage && !(selectedImage == value)">
          <img class="pa-1" height="120" :src="selectedImage" :alt="''"
            v-if="fileType == 'jpeg' || fileType == 'jpg' || fileType == 'png' || fileType == 'gif' || fileType == 'svg'" />
        </v-col>

        <v-col :cols="selectedImage && oldFile ? 12 : 6" :class="{ 'd-flex justify-center': selectedImage && oldFile }">
          <div>
            <div class="subtitle-1 font-weight-bold text-start">{{ $t(label) | capitalize }} </div>
            <div class="body-2 text-start">
              {{ $t(
                  'drag the file and place it here, or click on upload from your device and choose the file from your files'
                ) | capitalize
              }}
            </div>
            <div>
              <v-btn outlined :color="color"   class="d-block mt-3">
                {{ $t('upload from your device') }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

  </vue-dropzone>
</template>


<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "DropZone",
  props: {
    backValueMethod: { type: Function },
    value: { default: null },
    type: { default: 'dropzone' },
    name: { default: null },
    label: { default: null },
    required: { default: false },
    isLoading: { default: null },
    icon: { default: null },
    hideDetails: { default: false },
    multi: { default: false },
    classes: { default: null },
    color: { default: 'primary' },
  },
  data: () => ({
    selectedImages: [],
    selectedImage: null,
    dropzoneOptions: null,
    oldFile: null,
  }),
  computed: {
    fileType() {
      if (this.selectedImage) {
        return this.$global.FILE_TYPE(this.selectedImage)
      } else {
        return 'none'
      }
    },
    input: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("input", this.selectedImage);
      },
    },
    endpointURL() {
        return this.$api.serverUrl.slice(-1) === "/"
          ? this.$api.serverUrl.slice(0, -1)
          : this.$api.serverUrl;
      },
  },
  watch: {
    selectedImage() {
      this.backValueMethod(this.selectedImage)
    },
    selectedImages() {
      this.backValueMethod(this.selectedImages)
    },
    value() {
      if (this.multi == false && this.value && String(this.value).substring(0, 4) != 'data') {
        this.oldFile = this.value
      }
      if (this.multi == true && this.value && String(this.value).substring(0, 4) != 'data') {
        this.selectedImages =this.value
      }
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  mounted() {


    this.dropzoneOptions = {
      url: "http://localhost",
      headers: { "My-Awesome-Header": "header value" },
      // thumbnailWidth: 250,
      clickable: true,
      maxFilesize: 2,
      addRemoveLinks: true,
      dictRemoveFile: this.$i18n.t("remove"),
      maxFiles: this.multi == true ? 10 : 1,
      autoProcessQueue: false,
    };
  },
  beforeDestroy: function () {

  },
  methods: {
    async maxFilesReached(image) {
      console.log(image);
    },
    async onImageChange(image) {
      var reader = new FileReader();
      let multiImage = this.multi;
      var base64arr = [];
      var images = new Promise(function (resolve, reject) {
        reader.readAsDataURL(image);
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
          reject(error);
          alert(error);
        };
      });
      if (await images) {
        if (multiImage == true) {
          base64arr.push(await images);
          this.selectedImages.push(await images);
          this.$emit("input", this.selectedImages);
        }
        if (multiImage == null || multiImage == false) {
          this.selectedImage = await images;
          this.$emit("input", this.selectedImage);
        }
      }
    },

    removeItem(index) {
      this.selectedImages.splice(index, 1);
    },
  },
};
</script>



<style scoped lang="scss">
.theme--dark .vue-dropzone {
  background: transparent !important;
  border: 2px solid #181818;
}

.formControl .dropz .formControl .dropzone {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  border: 2px dashed #c2cdda;
  border-radius: 10px;
  margin-top: 0.5rem;
}

.formControl .dz-error-mark,
.formControl .dz-error-message {
  display: none !important;
}

.dropzone .dz-preview .dz-image img {
  margin: auto;
}

.formControl .dropzone .dz-preview:not(.dz-processing) .dz-progress {
  display: none !important;
}

.dropzone .dz-preview.dz-image-preview {
  background: transparent;
  min-width: 270px;
}

.formControl .vue-dropzone>.dz-preview .dz-remove {
  right: 25%;
  left: 25%;
  border: 0;
  background: #ff4141;
  border-radius: 5px;

  &:hover {
    text-decoration: none;
    opacity: 0.85;
  }
}

.dropzone .dz-message {
  text-align: center;
  margin: 0;
}

.formControl .dropzone-custom-title {
  margin-top: 0;
  // color: #00b782;
}

.formControl .subtitle {
  color: #314b5f;
}

.vue-dropzone>.dz-preview .dz-details {
  border-radius: 10px;
  background-color: #c2cdda;
}

.formControl .dropzone-custom-content {
  width: 100%;
  height: 100%;
  // background: #f5f6fa;
  // border: 0.5px solid #e1e1e1;
  border-radius: 5px;
  padding: 1rem 0;
  position: relative;

  .error {
    background-color: transparent !important;
    border-color: #ff4141 !important;
  }

  .plus {
    display: none;
  }

  .imgsPreview {
    display: flex;
    justify-content: center;
  }
}
</style>