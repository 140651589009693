<template>
  <section id="profile-page">
    <!-- breadcrumb -->
    <v-card flat color="light" class="rounded-0">
      <v-container>
        <h3 class="text-h6 font-weight-bold text-uppercase">{{ $t('my account') }}</h3>
        <h6 class="subtitle-2 text-capitalize">
          {{ $t('home') }}
          <v-icon size="20">{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
          {{ $t('my account') }}
        </h6>
      </v-container>
    </v-card>
    <v-container fluid class="px-8">
      <v-row justify="center" justify-sm="start">
        <v-col cols="12" md="3" lg="3" class="px-0 px-md-2">
          <v-card class="pa-5 shadow-none rounded-lg" outlined>
            <h5 class="text-h5 primary--text mt-3 font-weight-bold">
              {{ $t("hello") }} {{ $store.getters.user.name || "user" }}
            </h5>
            <div class="subtitle-1 gray2--text text-lowercase mt-1">
              {{ $store.getters.user.email || "email" }}
            </div>
            <v-divider class="mt-3" />
            <v-list class="overflow-hidden transparent rounded-lg">
              <v-list-item active-class="activeItem" class="mt-3" link exact :to="`/${$i18n.locale}/profile`"
                color="gray2">
                <v-list-item-title class="font-weight-medium">
                  {{ $t("my profile") | capitalize }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item active-class="activeItem" class="mt-3" link exact :to="`/${$i18n.locale}/profile/orders`"
                color="gray2">
                <v-list-item-title class="font-weight-medium">
                  {{ $t("my orders") | capitalize }}
                </v-list-item-title>
              </v-list-item>


              <v-list-item active-class="" class="mt-3" color="error" @click="logout">
                <v-list-item-title class="font-weight-bold error--text">
                  {{ $t("logout") | capitalize }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" md="9" lg="8" class="px-0 px-md-2">
          <!-- <nuxt-child keep-alive /> -->
          <router-view />
        </v-col>
      </v-row> </v-container>
  </section>
</template>




<script>
// import GenericInput from '@/components/ui/GenericInput.vue';



export default {
  name: "ProfilePage",
  components: {
    // GenericInput
  },

  data: () => ({
    tab: 0,
    valid: false,
    isLoading: false,
    form: {
      email: null,
      password: null,
      confirm_password: null,
      name: null,
    }
  }),
  head() { },
  mounted() {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    logout() {
      this.$global.DELETE_ALL_COOKIES();
      this.$api.LOGOUT(true);
      this.$router.push(`/${this.$i18n.locale}/authentication`);
    }
  },
};
</script>

<style lang="scss" scoped>
#profile-page {
  background: var(--white);
  min-height: 100vh;
  width: 100vw;

  .activeItem {
    color: var(--primary);
  }

  .v-list-item--link:before {
    background: transparent !important;
  }
}
</style>
