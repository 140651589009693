<template>
  <div>
    <div class="image-input" :style="`width:${width}px;height:${height}px;`" @click="chooseImage">
      <div class="image-container">
        <!-- default image -->
        <img loading="lazy" v-if="!img && !image_is_changed" :src="defaultImag" />
        <!-- Live Image -->
        <img loading="lazy" v-if="img" :src="image_is_changed ? local_image : useServerUrl ? ($store.state.endpointURL + img) : img" />
      </div>
      <input class="file-input" ref="fileInput" type="file"  @change="handleFileChange" @input="image_method">
    </div>
  </div>
</template>


<script>
export default {
  name: "AvatarProfile",
  data: () => ({
    image_is_changed: false,
    local_image: '',
  }),
  props: {
    width: { default: 112 },
    height: { default: 112 },
    showText: { default: true },
    text: { default: 'personal picture' },
    img: { default: null },
    base64: { default: false },
    useServerUrl: { default: true },
    defaultImag: { default: require('@/assets/img/svg/profileBig.svg') },
    callBackMethod: { type: Function }
  },
  computed: {

  },
  methods: {
    image_method() {
      this.image_is_changed = true;
      const input = this.$refs.fileInput
      const files = input.files
      if (files && files[0]) {
        const reader = new FileReader;
        if (this.base64) {
          reader.onload = e => {
            this.callBackMethod(e.target.result, this.image_is_changed)
          }
          reader.readAsDataURL(files[0])
          this.$emit('input', files[0])
        }
        else {
          this.callBackMethod(files[0], this.image_is_changed)
        }
      }
    },
    chooseImage() {
      this.$refs.fileInput.click()
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.local_image = e.target.result;
        };

        reader.readAsDataURL(file);
      }
    }
  }


};
</script>



<style scoped lang="scss">
.image-input div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.image-input {
  display: block;
  cursor: pointer;
  border-radius: 50%;
  background-size: 100% 100%;
  background-position: center center;
  margin: auto;
  z-index: 1;
}

.image-container {
  width: 100%;
  height: 100%;
}

.file-input {
  display: none
}
</style>
