<template>
  <section class="productsRowSection">
    <v-container>


      <v-row>
        <v-col cols="12" lg="6" v-for="(slider, index) in sliders.slice(0, 2)" :key="index">
          <v-col cols="12" class="mb-3">
            <h3 class="text-h5"> {{ slider.name }}</h3>
            <v-divider />
          </v-col>
          <v-slide-group mobile-breakpoint="991" multiple>
            <template v-for="(product, index) in slider.products">
              <v-slide-item v-if="index % 2" :key="index">
                <div style="width:100%">
                  <ProductCardTwo :width="`${cardWidth}px`"
                    :cardMargin="$store.state.windowSize.x <= 991 ? '10px' : ''" :product="product"
                    :cardClicked="showProduct"  />
                  <ProductCardTwo :width="`${cardWidth}px`"
                    :cardMargin="$store.state.windowSize.x <= 991 ? '10px' : ''"
                    v-if="slider.products.length !== index + 1" :product="slider.products[index + 1]"
                    :cardClicked="showProduct" />
                </div>
              </v-slide-item>
            </template>


          </v-slide-group>
        </v-col>

      </v-row>

    </v-container>
  </section>
</template>

<script>
import ProductCardTwo from '@/components/ui/ProductCardTwo.vue';
export default {
  name: "ProductsRowSection",
  components: { ProductCardTwo },
  data: () => ({
    activeCategory: 0,
  }),
  props: {
    sliders: { default: [] },
    type: { default: 1 },
    title: { default: null },
    show_route: { default: null },
  },
  watch: {},
  mounted() {

  },
  computed: {
    cardWidth() {
      if (this.$vuetify.breakpoint.xl) {
        return 700;
      }
      else if (this.$vuetify.breakpoint.lg) {
        return 570;
      }
      else if (this.$vuetify.breakpoint.md) {
        return 420;
      }
      if (this.$vuetify.breakpoint.sm) {
        return 420;
      }
      else { return this.$store.state.windowSize.x / 2; }
    },
  },
  methods: {

    showProduct({ brand_name, name, id }) {
      let route = this.$global.RouteParam(`/${this.$i18n.locale}/products/${brand_name ? brand_name : 'brand'}/${name.replaceAll('/', '-')}?id=${id}`)
      return route;
    },
  }
}
</script>

<style lang="scss" scoped>
.productsRowSection {
  position: relative;
  margin-bottom: 60px;

  .text-h5 {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--gray3);
  }

  .v-divider {
    position: relative;
    width: 50px;
    margin-top: 10px;
    display: block;

    &::after {
      content: '';
      background-color: var(--gray9);
      width: 50px;
      height: 3px;
      position: absolute;
      left: 0;
      top: -2px;
      border-radius: 25px;
    }
  }

}</style>