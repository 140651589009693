<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <h6 class="subtitle-1">{{ $t('corporate account') }}</h6>
      </v-col>
      <GenericInput type="text" :value="form.company_name" @input="form.company_name = $event" paddingY="py-2"
        label="Company Name (as in CR)" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" />

      <GenericInput type="text" :value="form.company_address" @input="form.company_address = $event" paddingY="py-2"
        label="Company Address" :required="true" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" />

      <GenericInput type="text" :value="form.company_phone" @input="form.company_phone = $event" paddingY="py-2"
        label="Company Official Phone Number" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" />

      <GenericInput type="text" :value="form.company_website" @input="form.company_website = $event" paddingY="py-2"
        label="Company Website" :required="true" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" />

      <GenericInput type="text" :value="form.company_social" @input="form.company_social = $event" paddingY="py-2"
        label="Company Profile link on social media" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" />

      <GenericInput type="text" :value="form.company_ceo_name" @input="form.company_ceo_name = $event" paddingY="py-2"
        label="Company CEO / Owner Full Name" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" />

      <GenericInput type="mobile" :value="form.company_ceo_mobile" @input="form.company_ceo_mobile = $event"
        paddingY="py-2" label="Company CEO / Owner Phone Number" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" />

      <GenericInput type="email" :value="form.company_ceo_email" @input="form.company_ceo_email = $event" paddingY="py-2"
        label="Company CEO / Owner Email" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" />

      <GenericInput type="file" :value="form.company_ceo_id_front" @input="form.company_ceo_id_front = $event"
        paddingY="py-2" :multi="false" label="ID Photo for Company CEO / Owner (Front)" :required="true"
        :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" hint="(PNG - JPG - PDF)" />

      <GenericInput type="file" :value="form.company_ceo_id_back" @input="form.company_ceo_id_back = $event"
        paddingY="py-2" :multi="false" label="ID Photo for Company CEO / Owner  (Rear)" :required="true"
        :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" hint="(PNG - JPG - PDF)" />

      <!-- second tab -->
      <v-col cols="12" class="py-2 px-16 mt-3">
        <v-divider />
      </v-col>
      <v-col cols="12" class="py-2 mt-3">
        <div class="body-2 font-weight-black text-start">
          {{ $t("authorizedPerson") }}
        </div>
      </v-col>
      <GenericInput type="text" :value="form.auth_person_name" @input="form.auth_person_name = $event" paddingY="py-2"
        label="Authorized Person Full Name" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" hint="" />

      <GenericInput type="mobile" :value="form.auth_person_mobile" @input="form.auth_person_mobile = $event"
        paddingY="py-2" label="Authorized Person Phone Number" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" hint="" />

      <GenericInput type="email" :value="form.auth_person_email" @input="form.auth_person_email = $event" paddingY="py-2"
        label="Authorized Person E-mail Address" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" hint="" />

      <GenericInput type="file" :value="form.auth_person_id_front" @input="form.auth_person_id_front = $event"
        paddingY="py-2" :multi="false" label="Authorized Person ID Front" :required="true" :isLoading="false"
        :cols="[12, 12, 12]" :inputDesign="inputDesign" hint="(PNG - JPG - PDF)" />

      <GenericInput type="file" :value="form.auth_person_id_back" @input="form.auth_person_id_back = $event"
        paddingY="py-2" :multi="false" label="Authorized Person ID Back" :required="true" :isLoading="false"
        :cols="[12, 12, 12]" :inputDesign="inputDesign" hint="(PNG - JPG - PDF)" />

      <GenericInput type="file" :value="form.tax_card" @input="form.tax_card = $event" paddingY="py-2" :multi="false"
        label="Tax Card" :required="true" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign"
        hint="upload file PDF" />

      <GenericInput type="file" :value="form.commercial_register" @input="form.commercial_register = $event"
        paddingY="py-2" label="Commercial Register" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" hint="upload file PDF" />

      <v-col cols="12" class="d-flex justify-end">
        <v-btn class="text-uppercase rounded-0 subtitle-1" :disabled="!(valid)" depressed large color="primary"
          :loading="isLoading" @click="sendForm">
          {{ $t('registration') }}
        </v-btn>
      </v-col>

    </v-row>
  </v-col>
</template>


<script>
export default {
  name: "CorporateForm",
  props: {
    accountTypes: { default: [] },
    hearingTypes: { default: [] },
    governments: { default: [] },
    categories: { default: [] },
    cities: { default: [] },
    cameras: { default: [] },
    form: { default: {} },
    valid: { default: false },
    isLoading: { default: false },
    inputDesign: { default: 'inline_label' },
  },
  data: () => ({
    step: 1,
  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    sendForm() {
      if (this.valid) {
        this.isLoading = true;
        this.$api.POST_METHOD_MULTIPART(`register_form_post`, this.form).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.$store.state.snackbarTime = 8500;
            this.$router.push(`/${this.$i18n.locale}/`)
          } else {
            this.$api.ShowModal('error', response.msg);
          }
        })
      } else {
        this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
      }
    }
  },
};
</script>



<style scoped lang="scss"></style>
