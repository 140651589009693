<template>
  <v-card class="ordersSection" flat>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" class="pb-0">
          <h6 class="text-h4">{{ $t("my orders") }}</h6>
        </v-col>
        <v-col cols="12" class="mt-2" v-for="(order) in orders" :key="order.id">
          <v-card  class="pa-5 rounded-lg" outlined>
            <v-row align="center" justify="space-between">
              <v-col cols="auto">
                <h5 class="text-h5">{{ $t("order no") }} : {{ order.id }}</h5>
              </v-col>
              <v-col cols="auto">
                <h5 class="text-h5 text--disabled">
                  {{ $t("placed on") }} {{ order.add_date | dateEn }}
                </h5>
              </v-col>
              <v-col cols="12">
                <h6 class="text-h6 text--disabled">
                  {{ $t("quantity") }} : {{ order.items_count }}
                </h6>
              </v-col>
              <v-col cols="12">
                <h6 class="text-h6 text--disabled">
                  {{ $t("total amount") }} : {{ order.price | float }}
                  {{ $t("egp") }}
                </h6>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn :to="`/${$i18n.locale}/orders/${order.id}`" color="primary" class="rounded-0"
                  min-width="180"  depressed large>
                  {{ $t("order details") }}
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <h5 class="text-h5 text-end error--text">
                  {{ $t("cancel order") }}
                </h5>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>

<script>


export default {
  name: "OrdersPage",
  components: {  },

  data: () => ({

    isLoading: false,
    orders: [],
  }),
  mounted() {
    this.orders = [
      {
        "id": 13,
        "items_count": 2,
        "brand_id": 2,
        "brand_name": "Canon",
        "name": "Canon RF 50mm f/1.2L USM",
        "image": "/upload/imgs/barcode-17.jpeg",
        "small_image": "/upload/imgs/barcode-17.jpeg",
        "old_price": 350,
        "price": 350,
        "product_active": 1,
        "add_date": "2023-11-22 03:37:24",
        "add_user": null
      },
      {
        "id": 17,
        "items_count": 2,
        "brand_id": 2,
        "brand_name": "Canon",
        "name": "Canon RF 50mm f/1.2L USM",
        "image": "/upload/imgs/barcode-17.jpeg",
        "small_image": "/upload/imgs/barcode-17.jpeg",
        "old_price": 350,
        "price": 350,
        "product_active": 1,
        "add_date": "2023-11-22 03:37:24",
        "add_user": null
      },
    ]
  },
  computed: {

  },
  watch: {

  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ordersSection {
  .text-h4 {
    font-weight: 600;
    font-size: 24px !important;
    line-height: 36px;
    color: var(--gray2);
  }

  .text-h5 {
    font-weight: 600;
    font-size: 16px !important;
    line-height: 24px;
  }

  .text-h6 {
    font-weight: 600;
    font-size: 16px !important;
    line-height: 24px;
    color: var(--primary);
  }
}
</style>
