import Vue from "vue";
import Vuex from "vuex";
import Settings from "./Settings";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    WEBSITE_URL: "https://pixipine.net",
    endpointURL: "https://new-api.pixipine.net/", // Live
    // endpointURL: "https://new-api.pixipine.net/", //  Testing
    app_version: process.env.VUE_APP_VERSION,
    apiAccess: "xcVBybDQhtDRqPxFUkpe1JVsZqyGGBR1v1KqendQ2qcLbqXB9hgqin41uRJ3qAQ057ibshUICVNfyuZyjSoM0yHJUGlKiWBpbwFVZl0yFzBLwpnFRCUuLxEP4bCNChVrVhOx8CokQmJ_unBMFtVSgZbuYf9sBubou5pWBxu1okFMC2ksCfk3jxdTQmeHq5xBlOL3pmkqq32L26LX1EBe",
    isAuth: false,
    splashScreen: true,
    offline: false,
    language: localStorage.getItem("language") == "en" ? "en" : "ar",
    sidebarIsOpen: false,
    cartIsOpen: false,
    accessToken: null,
    userData: {},
    windowSize: { x: 0, y: 0, offsetTop: 0 },
    showSnackbar: false,
    snackbarType: 'info',
    snackbarTime: 4500,
    snackbarTitle: null,
    snackbarMessages: [],
    wishlist: [],
    cart: {
      isLoading: false,
      products: [],
      merged_products: [],
      total_without_vat: 0,
      total_vat: 0,
      total_with_vat: 0,
      total_quantity: 0,
      total_pieces: 0,
      coupon_code: 0,
      discount: 0,
      discount_amount: 0,
      discount_type: 'value',
      total_after_discount: 0,
    },
    basic_data: {},
    homePage: {
      sliders: [],
      ads: [],
      featuredProducts: [],
      sliders_collections: [],
      large_product_section: [],
      other_collections: [],
      departments: [],
      categories: [],
      brands: [],
    }
  },
  mutations: {
    SET_DEFAULTS_DATA(state, data) {
      console.log(state, data);
      state.homePage = {
        sliders: [],
        ads: [],
        featuredProducts: [],
        sliders_collections: [],
        large_product_section: [],
        other_collections: [],
        departments: [],
        categories: [],
        brands: [],
      }
      state.splashScreen = false;
      state.homePage.sliders = data.sliders || [];
      state.homePage.ads = data.ads || [];
      state.homePage.brands = data.brands || [];
      state.homePage.departments = data.departments || [];
      state.homePage.categories = data.categorys || [];
      state.basic_data = data.basic_data.at(0) || {};

      if (data.collections.length) {
        state.homePage.featuredProducts.push(data.collections.at(0), data.collections.at(1))
        data.collections.splice(0, 2)
        state.homePage.sliders_collections = [...data.collections]
        data.collections.splice(0, 2)
        state.homePage.large_product_section = [...data.collections]
        data.collections.splice(0, 1)
        state.homePage.other_collections = [...data.collections]
      }
    },
    UPDATE_PROMO_CODE(state, promoCode) {
      if (promoCode) {
        state.cart.coupon_code = promoCode.coupon_code;
        state.cart.discount_type = promoCode.discount_type;
        state.cart.discount = promoCode.discount;
        if (promoCode.discount_type == 'value') {
          state.cart.total_after_discount = state.cart.total_with_vat - promoCode.discount;
          state.cart.discount_amount = state.cart.total_with_vat - state.cart.total_after_discount;
        } else {
          state.cart.discount_amount = (+promoCode.discount / 100) * +state.cart.total_with_vat;
          state.cart.total_after_discount = state.cart.total_with_vat - state.cart.discount_amount;
        }
      } else {
        state.cart.coupon_code = 0;
        state.cart.discount_type = 'value';
        state.cart.discount = 0;
        state.cart.total_after_discount = state.cart.total_with_vat;
        state.cart.discount_amount = 0;
      }
      state.cart.isLoading = false
    },
    UPDATE_CART(state, updated_products) {
      updated_products.forEach(product => {
        product.total_with_vat = +product.quantity * +product.price;
        let rate = (0 / 100) + 1
        product.total_without_vat = product.total_with_vat / rate;
        product.total_vat = product.total_with_vat - product.total_without_vat;
      });
      state.cart.products = [...updated_products];
      state.cart.total_quantity = updated_products.length;
      state.cart.total_pieces = updated_products.reduce((prevQuantity, product) => prevQuantity + +product.quantity, 0)
      state.cart.total_with_vat = updated_products.reduce((prevPrice, product) => prevPrice + +product.total_with_vat, 0)
      state.cart.total_after_discount = updated_products.reduce((prevPrice, product) => prevPrice + +product.total_with_vat, 0)
      state.cart.total_vat = updated_products.reduce((prevPrice, product) => prevPrice + +product.total_vat, 0)
      state.cart.total_without_vat = updated_products.reduce((prevPrice, product) => prevPrice + +product.total_without_vat, 0)
      this.commit("UPDATE_PROMO_CODE", state.cart)
      state.cart.isLoading = false
    },
    UPDATE_WISHLIST(state, wishlist) {
      state.wishlist = [...wishlist];
    },
  },
  getters: {
    user(state) {
      if (state.isAuth) {
        return state.userData
      } else {
        return {
          name: null,
          mobile: null,
          image: null,
          email: null,
          notifications_count: 0,
        }
      }
    },
    homePage(state) {
      return state.homePage
    },
    isAuth(state) {
      return state.isAuth
    },
    getAllCart(state) {
      return (state.cart)
    },
    getAllWishList(state) {
      return (state.wishlist)
    },
  },
  actions: {
    checkAuth({ state }) {
      // state.splashScreen = true;
      // this.$axios.defaults.config.baseURL = ''
      state.isAuth = !!localStorage.getItem(
        Vue.prototype.$global.CRYPT("cloud", "access-token")
      );
      if (!state.isAuth) {
        //  state.splashScreen = false;
        // this.app.router.push('/login')
      } else {
        state.accessToken = Vue.prototype.$global.DECRYPT(
          "cloud",
          localStorage.getItem(
            Vue.prototype.$global.CRYPT("cloud", "access-token")
          )
        );
        axios.defaults.headers.common.Authorization =
          "Bearer " + state.accessToken;
        // state.isAuth = true
      }
    },
    getUserData() {
      Vue.prototype.$api.GET_METHOD(`get_my_profile`).then((response) => {
        if (response.check) {
          console.log('response.data', response.data);
          Vue.prototype.$api.SET_AUTH(response.data);
        }
      });
    },
    checkAppLanguage({ state }) {
      return state.language;
    },
    checkLang({ state }) {
      Vue.prototype.$i18n.locale = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
      state.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
      state.Settings.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
    },
    getWebsiteData({ state, commit }) {
      axios.defaults.baseURL = state.endpointURL + `api/v1/${state.Settings.language}/front/`;
      setTimeout(() => {
        Vue.prototype.$api.GET_METHOD(`home`).then((response) => {
          if (response.check) {
            commit("SET_DEFAULTS_DATA", response.data);
          }
        })
      }, 1);
    },
    // Wishlist
    addToWishlist({ state, dispatch, commit }, new_product) {
      let wishlist_products = state.wishlist;
      if (wishlist_products.some((product) => product.product_id === new_product.product_id)) { // Found In wishlist
        const getIndex = wishlist_products.findIndex((product) => product.product_id === new_product.product_id);
        let product = { ...wishlist_products[getIndex] };
        product.index = getIndex;
        if (state.isAuth) {
          dispatch("updateOnlineWishlist", product);
        } else {
          product.offline_product = true;
          wishlist_products[getIndex] = product;
          commit("UPDATE_WISHLIST", wishlist_products);
          dispatch("wishlistCookies", wishlist_products);
        }
      } else {
        new_product.quantity++;
        if (state.isAuth) {
          dispatch("addToOnlineWishlist", new_product);
        } else {
          new_product.offline_product = true;
          wishlist_products.push(new_product);
          commit("UPDATE_WISHLIST", wishlist_products);
          dispatch("wishlistCookies", wishlist_products);
        }
      }
    },
    wishlistCookies({ state }, new_wishlist_products) {
      console.log(state);
      const STRINGIFY = JSON.stringify(new_wishlist_products);
      Vue.prototype.$global.SET_COOKIE('user_wishlist_products', STRINGIFY, 360).then(() => {

      })
    },
    getWishlist({ commit }) {
      Vue.prototype.$global.GET_COOKIE('user_wishlist_products').then((status) => {
        if (status) {
          const PARSE = JSON.parse(status)
          if (typeof PARSE === 'object') {
            commit("UPDATE_WISHLIST", PARSE);
          }
        }
      })
    },
    removeFromWishlist({ state, dispatch, commit }, selected_product) {
      let wishlist_products = state.wishlist;
      if (wishlist_products.some((product) => product.product_id === selected_product.product_id)) {
        const getIndex = wishlist_products.findIndex((product) => product.product_id === selected_product.product_id);
        wishlist_products.splice(getIndex, 1);
        commit("UPDATE_WISHLIST", wishlist_products);
        dispatch("wishlistCookies", wishlist_products);
      }
    },
    // Cart
    addToCart({ state, dispatch, commit }, new_product) {
      state.cart.isLoading = true;
      let cart_products = state.cart.products;
      if (cart_products.some((product) => product.product_id === new_product.product_id)) { // Found In Cart
        const getIndex = cart_products.findIndex((product) => product.product_id === new_product.product_id);
        let product = { ...cart_products[getIndex] };
        product.index = getIndex;
        product.quantity++;
        if (state.isAuth) {
          dispatch("updateOnlineCart", product);
        } else {
          product.offline_product = true;
          cart_products[getIndex] = product;
          commit("UPDATE_CART", cart_products);
          dispatch("cartCookies", cart_products);
        }
      } else {
        new_product.quantity++;
        if (state.isAuth) {
          dispatch("addToOnlineCart", new_product);
        } else {
          new_product.offline_product = true;
          cart_products.push(new_product);
          commit("UPDATE_CART", cart_products);
          dispatch("cartCookies", cart_products);
        }
      }
    },
    updateSpecificProduct({ state, dispatch, commit }, selected_product) {
      state.cart.isLoading = true;
      let cart_products = state.cart.products;
      if (cart_products.some((product) => product.product_id === selected_product.product_id)) {
        const getIndex = cart_products.find((product) => +product.id === +selected_product.id);
        cart_products.splice(getIndex, 1);
        commit("UPDATE_CART", [...cart_products]);
        dispatch("addToCart", selected_product, false);
      } else {
        dispatch("addToCart", selected_product, true);
      }
    },
    removeFromCart({ state, dispatch, commit }, selected_product) {
      let cart_products = state.cart.products;
      if (cart_products.some((product) => product.product_id === selected_product.product_id)) {
        state.cart.isLoading = true;
        const getIndex = cart_products.findIndex((product) => product.product_id === selected_product.product_id);
        let product = { ...cart_products[getIndex] };
        product.index = getIndex;
        if (state.isAuth) {
          if (cart_products[getIndex].quantity == 1 || selected_product.remove_product) {
            dispatch("removeFromOnlineCart", product);
          } else {
            product.quantity--
            dispatch("updateOnlineCart", product);
          }
        } else {
          if (cart_products[getIndex].quantity == 1 || selected_product.remove_product) {
            cart_products.splice(getIndex, 1);
          } else {
            cart_products[getIndex].quantity--
          }
          commit("UPDATE_CART", cart_products);
          dispatch("cartCookies", cart_products);
        }
      }
    },
    cartCookies({ state }, new_cart_products) {
      console.log(state);
      const STRINGIFY = JSON.stringify(new_cart_products);
      Vue.prototype.$global.SET_COOKIE('user_cart', STRINGIFY, 360).then(() => {

      })
    },
    getCART({ state, dispatch, commit }) {
      state.cart.isLoading = true;
      Vue.prototype.$global.GET_COOKIE('user_cart').then((status) => {
        if (status) {
          const PARSE = JSON.parse(status)
          if (typeof PARSE === 'object') {
            commit("UPDATE_CART", PARSE);
            state.cart.isLoading = false;
            if (PARSE.length && state.isAuth) {
              // dispatch("mergeCart", PARSE)
            }
            else if (PARSE.length == 0 && state.isAuth) {
              dispatch("getOnlineCART");
            }
          } else {
            if (state.isAuth) {
              dispatch("getOnlineCART");
            } else {
              state.cart.isLoading = false;
            }
          }
        }
        else {
          state.cart.isLoading = false;
          if (state.isAuth) {
            dispatch("getOnlineCART");
          }
        }
      })
    },
    setPromoCode({ commit }, promoCode) {
      commit("UPDATE_PROMO_CODE", promoCode);
    },
    getProductCart({ state }, id) {
      const FIND = state.cart.products.find(product => +product.id === +id)
      return FIND
    },
    clearCart({ state, commit }) {
      state.cart.isLoading = true;
      Vue.prototype.$global.DELETE_COOKIE('user_cart');
      commit("UPDATE_CART", []);
    },
    // 
    getOnlineCART({ state, commit }) {
      state.cart.isLoading = true;
      Vue.prototype.$api.GET_METHOD(`cart`).then((response) => {
        if (response) {
          commit("UPDATE_CART", response.data.cart);
        } else {
          state.cart.isLoading = false;
        }
      })
    },
    addToOnlineCart({ state, commit }, new_product) {
      let cart_products = state.cart.products;
      Vue.prototype.$global.DELETE_COOKIE('user_cart');
      Vue.prototype.$api.POST_METHOD(`cart/add-to-cart`, new_product).then((response) => {
        if (response) {
          new_product.id = response.data.id
          cart_products.push(new_product);
          commit("UPDATE_CART", cart_products);
        } else {
          console.log('rrrr', response);
          state.cart.isLoading = false
        }
      })
    },
    mergeToOnlineCart({ state, commit }) {
      let cart_products = state.cart.products;
      cart_products.forEach(product => {
        Vue.prototype.$api.POST_METHOD(`cart/add-to-cart`, product, false, true).then((response) => {
          if (response.status) {
            // cart_products.push(new_product);
            commit("UPDATE_CART", cart_products);
          } else {
            state.snackbarTitle = 'this product with This Attributes is out of stock';
            state.snackbarType = 'error';
            state.showSnackbar = true
            state.cart.isLoading = false;
          }
        })
      });
    },
    updateOnlineCart({ state, dispatch, commit }, new_product) {
      let cart_products = state.cart.products;
      Vue.prototype.$api.POST_METHOD(`cart/update`, new_product, false, true).then((response) => {
        if (response.statusCode == 404 && !new_product.id) {
          const getIndex = cart_products.findIndex((product) => product.product_id === new_product.product_id && product.color_id === new_product.color_id && product.size_id === new_product.size_id);
          cart_products.splice(getIndex, 1);
          dispatch("clearCart");
          dispatch("addToOnlineCart", new_product);
        }
        else if (response.status) {
          cart_products[new_product.index] = new_product;
          commit("UPDATE_CART", cart_products);
        } else {
          state.snackbarTitle = 'no more in the stock';
          state.snackbarType = 'error';
          state.showSnackbar = true
          state.cart.isLoading = false;
        }
      })
    },
    removeFromOnlineCart({ state, commit }, product) {
      let cart_products = state.cart.products;
      Vue.prototype.$api.POST_METHOD(`cart/delete`, product).then((response) => {
        if (response) {
          cart_products.splice(product.index, 1);
          commit("UPDATE_CART", cart_products);
        } else {
          cart_products.splice(product.index, 1);
          Vue.prototype.$global.DELETE_COOKIE('user_cart');
          state.cart.isLoading = false
        }
      })
    },
    clearOnlineCart({ state, commit }) {
      state.cart.isLoading = true;
      Vue.prototype.$api.POST_METHOD(`cart/empty`, null).then((response) => {
        if (response) {
          commit("UPDATE_CART", []);
        } else {
          state.cart.isLoading = false
        }
      })
    },
  },

  modules: { Settings },
});
