<template>
  <v-navigation-drawer v-model="$store.state.sidebarIsOpen" :right="$i18n.locale !== 'en'" fixed dark class="gray1"
    temporar>
    <v-card id="sidebar" class="transparent">
      <!-- Sidebar  -->
      <v-slide-x-transition>
        <v-list>
          <v-list-item>
            <v-btn text exact active-class="secondary--text" large block class="d-flex justify-start"
              :to="`/${$i18n.locale}`">
              {{ $t("home") }}
            </v-btn>
          </v-list-item>
          <v-divider class="my-1" />
          <v-list-item>
            <v-btn text active-class="secondary--text" exact large block class="d-flex justify-start"
              :to="`/${$i18n.locale}/products`">
              {{ $t("products") }}
            </v-btn>
          </v-list-item>
          <v-divider class="my-1" />
          <template v-for="(department, index) in departments">
            <v-list-item :key="index">
              <v-btn text active-class="secondary--text" exact large block class="d-flex justify-start"
                :to="`/${$i18n.locale}/products?department=${department.id}`">
                {{ department.name }}
              </v-btn>
            </v-list-item>
            <v-divider class="my-1" :key="index" />

          </template>
          <v-list-item>
            <v-btn text active-class="secondary--text" large block class="d-flex justify-start"
              :to="`/${$i18n.locale}/registration`">
              {{ $t("signup") }}
            </v-btn>
          </v-list-item>
          <v-divider class="my-1" />
          <v-list-item>
            <v-btn text active-class="secondary--text" large block class="d-flex justify-start"
              :to="`/${$i18n.locale}/blogs`">
              {{ $t("blogs") }}
            </v-btn>
          </v-list-item>
          <v-divider class="my-1" />
          <v-list-item>
            <v-btn text active-class="secondary--text" large block class="d-flex justify-start"
              :to="`/${$i18n.locale}/about`">
              {{ $t("about us") }}
            </v-btn>
          </v-list-item>
          <v-divider class="my-1" />
          <v-list-item>
            <v-btn text active-class="secondary--text" large block class="d-flex justify-start"
              :to="`/${$i18n.locale}/contact`">
              {{ $t("contact us") }}
            </v-btn>
          </v-list-item>
          <v-divider class="my-1" />
          <v-list-item>
            <v-btn text active-class="secondary--text" large block class="d-flex justify-start"
              :to="`/${$i18n.locale}/authentication`">
              {{ $t("my account") }}
            </v-btn>
          </v-list-item>
          <v-divider class="my-1" />
          <v-list-item>
            <v-badge :content="wishList.length" :value="wishList.length" class="w-100" color="error" overlap  offset-y="20">
            <v-btn text active-class="secondary--text" large block class="d-flex justify-start"
              :to="`/${$i18n.locale}/wishlist`">
              {{ $t("wishlist") }}
            </v-btn>
            </v-badge>
          </v-list-item>
          <v-divider class="my-1" />
          <v-list-item>
            <v-badge :content="cart.length" :value="cart.length" class="w-100" color="error" overlap  offset-y="20">
            <v-btn text active-class="secondary--text" large block class="d-flex justify-start"
              :to="`/${$i18n.locale}/cart`">
              {{ $t("cart") }} 
            </v-btn>
            </v-badge>
          </v-list-item>
          <v-divider class="my-1" />
          <v-list-item>
            <v-btn text large block class="d-flex justify-start" @click="changeLang">
              {{ $i18n.locale == 'en' ? 'عربي' : 'English' }}
            </v-btn>
          </v-list-item>
          <!-- <v-divider class="my-1" />
          <v-list-item>
            <v-btn text active-class="secondary--text" large block class="d-flex justify-start"
              :to="`/${$i18n.locale}/auth/login`">
              {{ $t("login") }}
            </v-btn>
          </v-list-item>
          <v-divider class="my-1" />
          <v-list-item>
            <v-btn text active-class="secondary--text" large block class="d-flex justify-start"
              :to="`/${$i18n.locale}/auth/register`">
              {{ $t("create an account") }}
            </v-btn>
          </v-list-item> -->
          <v-divider class="my-1" />
          <v-list-item class="mt-3">
            <SearchInput />
          </v-list-item>
        </v-list>
      </v-slide-x-transition>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import SearchInput from "./SearchInput.vue";

export default {
  name: "SideBar",
  computed: {
    departments() {
      return this.$store.getters.homePage.departments || [];
    },
    wishList() {
      return this.$store.getters.getAllWishList
    },
    cart() {
      return this.$store.getters.getAllCart.products
    }
  },
  data: () => ({
    search: null,
  }),
  mounted() { },
  methods: {
    logout() {
      if (this.$auth.loggedIn) {
        this.$auth.logout();
      }
    },
    changeLang() {
      let current_path = this.$route.fullPath
      let change_path = current_path.replace(this.$i18n.locale, this.$i18n.locale === "en" ? "ar" : "en")
      localStorage.setItem('language', this.$i18n.locale === "en" ? "ar" : "en");
      this.$i18n.locale = this.$i18n.locale === "en" ? "ar" : "en";
      this.$vuetify.rtl = this.$i18n.locale === "ar";
      this.$router.push(change_path);
    },
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
  },
  components: { SearchInput },
};
</script>

<style scoped lang="scss">
#sidebar {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  box-shadow: none !important;
  position: fixed;
  width: 100%;
}
</style>
