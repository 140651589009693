import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import router from "../router";
import store from "../store";
import i18n from "./i18n";
//////////////////

///////// Axios Defaults Header /////////

axios.defaults.baseURL = store.state.endpointURL + "api/v1/" + store.state.Settings.language + "/front/";
axios.defaults.headers.common["access-token"] = store.state.apiAccess;

axios.defaults.headers.common["app_ver"] = `pixipine_website_${store.state.app_version}`;
// axios.defaults.timeout = 20000;
export default {
  install(Vue) {
    // define a global property
    Vue.PLUGIN_VERSION = "0.0.3";
    Vue.prototype.$api = {
      serverUrl: store.state.endpointURL,

      //  GET ------------------------------------------------------------
      async GET_METHOD(RESOURCE_NAME) {
        if (navigator.onLine == true) {
          try {
            const response = await axios.get(RESOURCE_NAME);
            if (response.data.check == false) {
              store.state.snackbarTitle = response.data.msg;
              store.state.snackbarType = 'error';
              store.state.showSnackbar = true;
            }
            if (response.data) {
              return response.data;
            }
          } catch (error) {
            if (error.response) {
              this.SetError(error.response.status, error);
              console.log(
                error.response.status,
                error.response.data,
                error.response.headers
              );
              return error;
            } else if (error.request) {
              console.log(error.request);
              this.SetError(null, error.request);
              return error;
            } else {
              this.SetError(null, error.message);
              console.log("Error", error.message);
              return error;
            }
          }
        } else {
          this.ShowModal("error", null, i18n.t("No Internet Connection"));
          return false;
        }
      },
      //  POST ------------------------------------------------------------
      async POST_METHOD(RESOURCE_NAME, NEW_DATA, TOAST = false, TOAST_MESSAGE = " ") {
        if (navigator.onLine == true) {
          try {
            const response = await axios.post(RESOURCE_NAME, NEW_DATA);
            if (TOAST) {
              store.state.snackbarTitle = TOAST_MESSAGE;
              store.state.snackbarType = 'success';
              store.state.showSnackbar = true;
            }
            if (!TOAST && response.data.check == true && response.data.msg) {
              store.state.snackbarTitle = response.data.msg;
              store.state.snackbarType = 'success';
              store.state.showSnackbar = true;
            }
            if (response.data.check == false) {
              store.state.snackbarTitle = response.data.msg;
              store.state.snackbarType = 'error';
              if (response.data.data) {
                Object.keys(response.data.data).forEach((key) => {
                  console.log(response.data.data[key]);
                  store.state.snackbarMessages.push(response.data.data[key][0])
                });
              }
              store.state.showSnackbar = true;
            }
            return response.data;
          } catch (error) {
            if (error.response) {
              this.SetError(error.response.status, error);
              console.log(
                "error ==>",
                error.response.status,
                error.response.data,
                error.response.headers
              );
              return false;
            } else if (error.request) {
              console.log("error ==>", error.request);
              this.SetError(null, error.request);
              return false;
            } else {
              this.SetError(null, error.message);
              console.log("error ==>", error.message);
              return false;
            }
          }
        } else {
          this.ShowModal("error", null, i18n.t("No Internet Connection"));
          return false;
        }
      },
      //  POST Multipart File ------------------------------------------------------------
      async POST_METHOD_MULTIPART(RESOURCE_NAME, NEW_DATA, TOAST = false, TOAST_MESSAGE = " ") {
        try {
          const formData = new FormData();
          Object.keys(NEW_DATA).forEach((key) => {
            formData.append(key, NEW_DATA[key]);
          });
          const response = await axios.request({
            method: "post",
            url: `${store.state.endpointURL}api/v1/${store.state.Settings.language}/front/${RESOURCE_NAME}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });
          if (TOAST) {
            store.state.snackbarTitle = TOAST_MESSAGE;
            store.state.snackbarType = "success";
            store.state.showSnackbar = true;
          }
          if (!TOAST && response.data.check == true && response.data.msg) {
            store.state.snackbarTitle = response.data.msg;
            store.state.snackbarType = "success";
            store.state.showSnackbar = true;
          }
          if (response.data.check == false) {
            store.state.snackbarTitle = response.data.msg;
            store.state.snackbarType = "error";
            if (response.data.data) {
              Object.keys(response.data.data).forEach((key) => {
                console.log(response.data.data[key]);
                store.state.snackbarMessages.push(response.data.data[key][0]);
              });
            }
            store.state.showSnackbar = true;
          }
          store.state.snackbarTime = 4500;
          store.commit('RESET_REQUEST_LOADER')
          return response.data;
        } catch (error) {
          if (error.response) {
            this.SetError(error.response.status, error, RESOURCE_NAME, NEW_DATA, 'POST');
            return false;
          } else if (error.request) {
            this.SetError(null, error.request, RESOURCE_NAME, NEW_DATA, 'POST');
            return false;
          } else {
            this.SetError(null, error.message, RESOURCE_NAME, NEW_DATA, 'POST');
            return false;
          }
        }
      },
      //  PUT ------------------------------------------------------------
      async PUT_METHOD(RESOURCE_NAME, ID, NEW_DATA, TOAST = false, TOAST_MESSAGE = " ") {
        if (navigator.onLine == true) {
          try {
            const response = await axios.put(
              RESOURCE_NAME + "/" + ID,
              NEW_DATA
            );
            console.log("response.data", response.data);
            if (TOAST) {
              this.ShowAlert("success", " ", TOAST_MESSAGE);
            }
            if (!TOAST && response.data.check == true) {
              this.ShowAlert("success", " ", response.data.msg);
            }
            if (response.data.check == false) {
              this.ShowAlert("error", i18n.t("Error"), response.data.msg);
            }
            return response.data;
          } catch (error) {
            console.log(error);
            this.SetError(error.response.status, error);
            return error;
          }
        } else {
          this.ShowModal("error", null, i18n.t("No Internet Connection"));
          return false;
        }
      },
      //  DELETE ------------------------------------------------------------
      async DELETE_METHOD(RESOURCE_NAME, ID, TOAST = false, TOAST_MESSAGE = " ") {
        if (navigator.onLine == true) {
          try {
            const response = await axios.delete(RESOURCE_NAME + "/" + ID);
            console.log("response.data", response.data);
            if (TOAST) {
              store.state.snackbarTitle = TOAST_MESSAGE;
              store.state.snackbarType = 'success';
              store.state.showSnackbar = true;
            }
            if (!TOAST && response.data.check == true) {
              store.state.snackbarTitle = response.data.msg;
              store.state.snackbarType = 'success';
              store.state.showSnackbar = true;
            }
            if (response.data.check == false) {
              this.ShowAlert("error", i18n.t("Error"), response.data.msg);
            }
            return response.data;
          } catch (error) {
            console.log(error);
            this.SetError(error.response.status, error);
          }
        } else {
          this.ShowModal("error", null, i18n.t("No Internet Connection"));
          return false;
        }
      },
      //  Download File ------------------------------------------------------------
      async DOWNLOAD_METHOD(RESOURCE_NAME, FILE_NAME = "file.jpg") {
        if (navigator.onLine == true) {
          try {
            const response = await axios.post(RESOURCE_NAME, null, {
              responseType: "blob",
            });
            console.log(response.data);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", FILE_NAME);
            document.body.appendChild(link);
            link.click();
            return response.data;
          } catch (error) {
            if (error.response) {
              this.SetError(error.response.status, error);
              console.log(
                error.response.status,
                error.response.data,
                error.response.headers
              );
              return error;
            } else if (error.request) {
              console.log(error.request);
              this.SetError(null, error.request);
              return error;
            } else {
              this.SetError(null, error.message);
              console.log("Error", error.message);
              return error;
            }
          }
        } else {
          this.ShowModal("error", null, i18n.t("No Internet Connection"));
          return false;
        }
      },
      async DOWNLOAD_METHOD_GET(RESOURCE_NAME, FILE_NAME = "file.jpg", Method = 'GET') {
        if (navigator.onLine == true) {
          try {
            const url = `${store.state.endpointURL}api/v1/${store.state.Settings.language}/${RESOURCE_NAME}`;
            const response = await axios.request({
              url,
              Method,
              responseType: "blob",
            })
              .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", FILE_NAME); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
              });
            return response
          } catch (error) {
            if (error.response) {
              this.SetError(error.response.status, error);
              return error;
            } else if (error.request) {
              console.log(error.request);
              this.SetError(null, error.request);
              return error;
            } else {
              this.SetError(null, error.message);
              console.log("Error", error.message);
              return error;
            }
          }
        } else {
          this.ShowModal("error", null, i18n.t("No Internet Connection"));
          return false;
        }
      },

      async DOWNLOAD_METHOD_BODY(RESOURCE_NAME, BODY, FILE_NAME = "file.jpg") {
        if (navigator.onLine == true) {
          try {
            const response = await axios.post(RESOURCE_NAME, BODY, {
              responseType: "blob",
            });
            console.log(response.data);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", FILE_NAME);
            document.body.appendChild(link);
            link.click();
            return response.data;
          } catch (error) {
            if (error.response) {
              this.SetError(error.response.status, error);
              console.log(
                error.response.status,
                error.response.data,
                error.response.headers
              );
              return error;
            } else if (error.request) {
              console.log(error.request);
              this.SetError(null, error.request);
              return error;
            } else {
              this.SetError(null, error.message);
              console.log("Error", error.message);
              return error;
            }
          }
        } else {
          this.ShowModal("error", null, i18n.t("No Internet Connection"));
          return false;
        }
      },

      // const method = 'GET';
      // const url = `${store.state.endpointURL}api/v1/ar/${RESOURCE_NAME}`;
      // axios.request({
      //      url,
      //     method,
      //     responseType: "blob", 
      //   })
      //   .then(({ data }) => {
      //     const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      //     const link = document.createElement("a");

      //     link.href = downloadUrl;

      //     link.setAttribute("download", "file.png"); //any other extension

      //     document.body.appendChild(link);

      //     link.click();

      //     link.remove();
      //   });
      //   LOGIN ------------------------------------------------------------
      async LOGIN_AUTH(LOGIN_DATA, TOAST = true, TOAST_MESSAGE = i18n.t("Welcome Back")) {
        if (navigator.onLine == true) {
          try {
            const response = await axios.post("login", LOGIN_DATA);
            if (response.data.check == false) {
              this.ShowModal(
                "error",
                i18n.t("Wrong Username or Password"),
                response.data.msg
              );
            } else {
              if (TOAST) {
                console.log(TOAST_MESSAGE);
                // this.ShowModal(
                //   "success",
                //   i18n.t("Logged in successfully"),
                //   TOAST_MESSAGE
                // );
                let login_date = new Date();
                localStorage.setItem("login_date", login_date);
              }
              this.SET_AUTH(response.data.data);
            }
            return response.data;
          } catch (error) {
            console.log(error);
            this.SetError(error.response.status, error);
          }
        } else {
          this.ShowModal("error", null, i18n.t("No Internet Connection"));
          return false;
        }
      },

      ///////// Set Auth Data /////////
      SET_AUTH(AUTH_DATA) {
        localStorage.setItem(
          Vue.prototype.$global.CRYPT('pixipine', 'access-token'),
          Vue.prototype.$global.CRYPT('pixipine', AUTH_DATA.api_token)
        )
        axios.defaults.headers.common.Authorization =
          'Bearer ' + AUTH_DATA.api_token
        store.state.userData = AUTH_DATA
        store.state.isAuth = true
        store.state.splashScreen = false;
        return AUTH_DATA
      },

      // SET Branch Year
      SET_USER_DEFAULTS() {
        let requestBody = {
          branch_id: store.state.current_branch,
          year_id: store.state.current_year,
          title_id: store.state.current_title,
        }
        this.POST_METHOD('change_user_defaults', requestBody, true, i18n.t("please wait until page loaded")).then((response) => {
          this.loading = false;
          if (response.check == true) {
            window.location.reload(true);
            router.push('/')
          }
        })
      },

      ///////// Check Internet Connection /////////
      CHECK_INTERNET() {
        return navigator.onLine;
      },
      ///////// Logout /////////
      LOGOUT(reload = true) {
        store.state.isAuth = false;
        store.state.accessToken = false;
        store.state.permissions = [];
        var lang = localStorage.getItem("language");
        localStorage.clear();
        localStorage.setItem("language", lang ? lang : "ar");
        store.state.current_branch = null;
        store.state.current_year = null;
        axios.defaults.headers.common["Authorization"] = null;
        axios.defaults.headers.common["clientID"] = null;
        if (reload == true) {
          window.location.reload(true);
        } else {
          router.push("/login").catch(() => { });
        }
      },

      ///////// API Errors Action /////////
      SetError(STATUS, ERROR) {
        switch (STATUS) {
          case 401:
            // not auth
            this.LOGOUT(true);
            break;
          case 404:
            // not found
            // router.push("/notfound");
            break;
          case 403:
            // not Auth
            this.LOGOUT(false);
            break;
          case 500:
            // server error
            this.ShowModal(
              "error",
              i18n.t("error happend"),
              i18n.t(
                "Try to refresh the page or feel free to contact us if the problem persists"
              )
            );
            break;

          default:
            this.ShowModal("error", "Request error", ERROR);
            break;
        }
      },

      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, MSG) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: true,
          timer: 3000,
          position:
            store.state.Settings.translation.rtl == true
              ? "top-end"
              : "top-start",
          showConfirmButton: false,
          showCloseButton: true,
          allowOutsideClick: true,
          timerProgressBar: true,
          allowEscapeKey: true,
          didOpen: () => {
            Swal.getHtmlContainer().addEventListener("mouseleave", () => {
              Swal.resumeTimer();
            });
            Swal.getHtmlContainer().addEventListener("mouseover", () => {
              Swal.stopTimer();
            });
          },
        });
      },

      ///////// Modal /////////
      ShowModal(TYPE, TITLE, MSG) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: false,
          timer: 3000,
          showConfirmButton: false,
          showCloseButton: false,
          allowOutsideClick: true,
          timerProgressBar: true,
          allowEscapeKey: true,
          didOpen: () => {
            Swal.getHtmlContainer().addEventListener("mouseleave", () => {
              Swal.resumeTimer();
            });
            Swal.getHtmlContainer().addEventListener("mouseover", () => {
              Swal.stopTimer();
            });
          },
        });
      },
      ///////// Print /////////
      PrintInvoice(URL, ID = 0) {
        // var printInvoice = window.open('_blank', 'فاتورة Pixipine - POS', 'width=50,height=50');
        // printInvoice.document.write('<html><head><style type="text/css"> img{opacity : 0;}@media print {img{opacity: 1;}} </style></head>');
        // printInvoice.document.write(`<img ${src}  onload="window.print(); window.close()" />`);
        // printInvoice.document.write('</body></html>');
        let printInvoice = document.createElement("iframe");
        printInvoice.name = "printInvoice_" + ID;
        printInvoice.style.position = "absolute";
        printInvoice.style.top = "-1000000px";
        document.body.appendChild(printInvoice);
        let invoice = printInvoice.contentWindow
          ? printInvoice.contentWindow
          : printInvoice.contentDocument.document
            ? printInvoice.contentDocument.document
            : printInvoice.contentDocument;
        invoice.document.open();
        invoice.document.write(
          `<html lang="ar"><head><title> فاتورة قيراط رقم ${ID}</title>`
        );
        invoice.document.write("</head><body>");
        invoice.document.write(
          `<img ${URL} onload="window.print(); window.close()"  />`
        );
        invoice.document.write("</body></html>");
        return false;
      },
      //  Print
      PrintInvoiceIframe(URL, ID = 0) {
        // var printInvoice = window.open('_blank', 'فاتورة Pixipine - POS', 'width=50,height=50');
        // printInvoice.document.write('<html><head><style type="text/css"> img{opacity : 0;}@media print {img{opacity: 1;}} </style></head>');
        // printInvoice.document.write(`<img ${src}  onload="window.print(); window.close()" />`);
        // printInvoice.document.write('</body></html>');
        let printInvoice = document.createElement('iframe');
        printInvoice.name = "printInvoice_" + ID;
        printInvoice.style.position = "absolute";
        printInvoice.style.top = "-1000000px";
        document.body.appendChild(printInvoice);
        let invoice = printInvoice.contentWindow ? printInvoice.contentWindow : printInvoice.contentDocument.document ? printInvoice.contentDocument.document : printInvoice.contentDocument;
        invoice.document.open();
        invoice.document.write(`<html lang="ar"><head><title> فاتورة قيراط رقم ${ID}</title>`);
        invoice.document.write('</head><body>');
        invoice.document.write(`<img ${URL} onload="window.print(); window.close()"  />`);
        invoice.document.write('</body></html>');
        return false;
      },
    };
  },
};
