<template>
  <v-card class="filter_card mb-16 rounded-0 mb-md-0" v-if="categories.length || brands.length || departments.length">
    <v-row justify="space-between" align="center">
      <v-col cols="auto">
        <h5 class="text-h5">{{ $t('filter') }}</h5>
      </v-col>
      <v-col cols="auto" v-if="type !== 5">
        <v-btn text color="primary" @click="clearFilter" class="text-decoration-underline">
          {{ $t('clear filter') }}</v-btn>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12" v-if="departments.length">
        <h6 class="text-h6 d-flex justify-space-between align-center cursor_pointer"
          @click="showDepartments = !showDepartments">
          <div> {{ $t('department') }}</div>
          <v-icon> {{ showDepartments ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </h6>
        <v-expand-transition>
          <div v-if="showDepartments">
            <v-col cols="12" class="d-flex cursor_pointer py-1 pb-0 align-center" @click="activeDepartment = 0">
              <v-icon>{{ activeDepartment == 0 ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
              <div class="subtitle-1 ms-2 gray5--text">
                {{ $t('all') }}
              </div>
            </v-col>
            <v-col cols="12" class="d-flex cursor_pointer py-1 pb-0 align-center"
              @click="activeDepartment = department.id" v-for="(department, index) in departments" :key="index">
              <v-icon>{{ activeDepartment == department.id ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
              <div class="subtitle-1 ms-2 gray5--text">
                {{ department.name }}
              </div>
            </v-col>
          </div>
        </v-expand-transition>

      </v-col>
      <!--  -->
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12" v-if="categories.length">
        <h6 class="text-h6 d-flex justify-space-between align-center cursor_pointer"
          @click="showCategories = !showCategories">
          <div> {{ $t('category') }}</div>
          <v-icon> {{ showCategories ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </h6>
        <v-expand-transition>
          <div v-if="showCategories">
            <v-col cols="12" class="d-flex cursor_pointer py-1 pb-0 align-center" @click="activeCategory = 0">
              <v-icon>{{ activeCategory == 0 ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
              <div class="subtitle-1 ms-2 gray5--text">
                {{ $t('all') }}
              </div>
            </v-col>
            <v-col cols="12" class="d-flex cursor_pointer py-1 pb-0 align-center" v-for="(category, index) in categories"
              :key="index" @click="activeCategory = category.id">
              <v-icon>{{ activeCategory == category.id ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
              <div class="subtitle-1 ms-2 gray5--text">
                {{ category.name }}
              </div>
            </v-col>
          </div>
        </v-expand-transition>
      </v-col>
      <!--  -->
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12" v-if="brands.length">
        <h6 class="text-h6 d-flex justify-space-between align-center cursor_pointer"
          @click="showBrands = !showBrands">
          <div> {{ $t('brand') }}</div>
          <v-icon> {{ showBrands ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </h6>
        <v-expand-transition>
          <div v-if="showBrands">
            <v-col cols="12" class="d-flex cursor_pointer py-1 pb-0 align-center" @click="activeBrand = 0">
              <v-icon>{{ activeBrand == 0 ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
              <div class="subtitle-1 ms-2 gray5--text">
                {{ $t('all') }}
              </div>
            </v-col>
            <v-col cols="12" class="d-flex cursor_pointer py-1 pb-0 align-center" @click="activeBrand = brand.id"
              v-for="(brand, index) in brands" :key="index">
              <v-icon>{{ activeBrand == brand.id ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
              <div class="subtitle-1 ms-2 gray5--text">
                {{ brand.name }}
              </div>
            </v-col>
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>


export default {
  name: "FilterCard",
  data: () => ({
    type_route: null,
    showDepartments: true,
    showCategories: true,
    showBrands: true,
  }),
  props: {
    categories: { default: [] },
    brands: { default: [] },
    departments: { default: [] },
    activeCategory: { default: 0 },
    activeBrand: { default: 0 },
    activeDepartment: { default: 0 },
    type: { default: 0 },
    clearFilter: { default: Function },
    setActive: { default: Function },
  },
  watch: {
    activeCategory() {
      this.setActive(this.activeDepartment, this.activeCategory, this.activeBrand);
    },
    activeBrand() {
      this.setActive(this.activeDepartment, this.activeCategory, this.activeBrand);
    },
    activeDepartment() {
      this.setActive(this.activeDepartment, this.activeCategory, this.activeBrand);
    },
  },
  mounted() {

  },
  methods: {

  },
  components: {}
}
</script>

<style lang="scss" scoped>
.filter_card {
  box-shadow: none !important;
  border-radius: 8px;
  border: 1px solid var(--gray10);
  height: auto;
  padding: 20px;
  position: sticky;
  top: 30px;

  .brandCard {
    box-shadow: none !important;
    border-radius: 8px;
    border: 1px solid var(--gray10);
    height: 100px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }

    &.activeFilter {
      border: 1px solid var(--primary);
      background-color: var(--secondary);
    }
  }

  @media screen and (max-width: 991px) {
    border: 0;
  }
}
</style>