<template>
  <section>
    <!-- breadcrumb -->
    <v-card flat color="light" class="rounded-0">
      <v-container>
        <h3 class="text-h6 font-weight-bold text-uppercase">{{ $t('about us') }}</h3>
        <h6 class="subtitle-2 text-capitalize">
          {{ $t('home') }}
          <v-icon size="20">{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
          {{ $t('about us') }}
        </h6>
      </v-container>
    </v-card>
    <v-container class="my-2">
      <v-row>
        <v-col cols="12" md="6">
          <div v-html="$store.state.basic_data.about" />
        </v-col>
        <v-col cols="12" md="6">
          <img class="d-block ma-auto" src="@/assets/img/png/about.jpg" alt="">
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>


<script>
export default {
  name: "AboutUs",
  components: {
  },
  mounted() {

  },
  data: () => ({

  }),
  methods: {

  },

}
</script>
<style scoped></style>