<template>
  <section class="w-100">
    <FirstBar />
    <MiddleBar />
    <ThirdBar />
  </section>
</template>
<script>

import FirstBar from "./FirstBar.vue";
import MiddleBar from "./MiddleBar.vue";
import ThirdBar from "./ThirdBar.vue";
export default {
  name: "AppBar",
  computed: {
  },
  data: () => ({

  }),
  components: { FirstBar, MiddleBar, ThirdBar },
  mounted() {

  },
  methods: {
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },

  },
};
</script>

<style lang="scss" scoped></style>