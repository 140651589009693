<template>
  <section>
    <!-- breadcrumb -->
    <v-card flat color="light" class="rounded-0">
      <v-container>
        <h3 class="text-h6 font-weight-bold text-uppercase">{{ $t('cart') }}</h3>
        <h6 class="subtitle-2 text-capitalize">
          {{ $t('home') }}
          <v-icon size="20">{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
          {{ $t('cart') }}
        </h6>
      </v-container>
    </v-card>


    <v-container fluid class="px-2 px-md-4 px-lg-8">
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <v-col cols="12" class="pt-0 my-3">
            <h4 class="text-h4">{{ $t("your cart") }}</h4>
          </v-col>
          <v-row>
            <v-col cols="6" md="12" class="" v-for="(product, index) in cart.products" :key="index">
              <ProductCardTwo imgHeight="120px" :cart="true" :product="product" :index="index"
                :cardClicked="showProduct" />
            </v-col>
          </v-row>
          <v-col cols="12" class="pt-0" v-if="cart.total_quantity == 0">
            <v-alert color="primary" outlined class="text-capitalize">
              <v-row justify="space-between" align="center">
                <v-col cols="auto">
                  {{ $t('your cart is empty') }}
                </v-col>
                <v-col cols="auto">
                  <router-link class="text-capitalize text-decoration-underline" :to="`/products`">
                    {{ $t('continue shipping') }}
                  </router-link>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-col>
        <v-col cols="1" class="d-lg-flex justify-center d-none">
          <v-divider vertical />
        </v-col>
        <v-col cols="12" md="6" lg="5">
          <v-card class="pa-2 pt-0  light rounded-0 shadow-none" style="border: 1px solid var(--gray10)">
            <v-col cols="12" class="pb-0">
              <h5 class="text-h5">{{ $t("enter a gift card or promotional code") }}!</h5>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row justify="space-between" align="center">
                <v-col cols="12" sm="9" md="8" lg="8">
                  <TextInput background="backgroundW" name="coupon_code" :clearable="true" placeholder="coupon code"
                    :dense="false" :value="coupon_code" :keydownEnter="applyCode" @input="coupon_code = $event"
                    :required="false" :isLoading="isCouponLoading" :cols="[12, 12, 12]" />
                </v-col>
                <v-col cols="12" sm="3" md="4" lg="4">
                  <v-btn color="primary" large block height="58" @click="applyCode"
                    class="shadow d-block mi-start-auto rounded-pill" :loading="isCouponLoading">{{ $t("apply code")
                    }}</v-btn>
                </v-col>
                <v-col cols="auto">
                  <h5 class="text-h5">{{ $t("total payment") }}</h5>
                </v-col>
                <v-col cols="auto">
                  <h5 class="text-h5 d-flex text-end">
                    {{ cart.total_after_discount + 0 | float }}
                    <span class="primary--text ms-1">{{ $t("egp") }}</span>
                    <h5 class="discount ms-2" v-if="cart.coupon_code">
                      {{ cart.total_with_vat || 0 | float }} {{ $t('egp') }}
                    </h5>
                  </h5>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-divider />
                </v-col>
                <v-col cols="8" class="pb-0">
                  <h5 class="subtitle-1 font-weight-regular gray7--text">
                    {{ $t("items") }}
                  </h5>
                </v-col>
                <v-col cols="4" class="pb-0 text-end">
                  <h5 class="subtitle-1 font-weight-bold">
                    {{ cart.total_quantity }}X ({{ cart.total_pieces }} {{ $t("piece") }})
                  </h5>
                </v-col>
                <v-col cols="8" class="pb-0">
                  <h5 class="subtitle-1 font-weight-regular gray7--text">
                    {{ $t("code promo") }}
                  </h5>
                </v-col>
                <v-col cols="4" class="pb-0 text-end">
                  <h5 class="subtitle-1 font-weight-bold">
                    {{ cart.discount_amount | float }}<span class="primary--text ms-2">{{ $t("egp") }}</span>
                  </h5>
                </v-col>
                <v-col cols="8" class="pb-0">
                  <h5 class="subtitle-1 font-weight-regular gray7--text">
                    {{ $t("delivery service") }}
                  </h5>
                </v-col>
                <v-col cols="4" class="pb-0 text-end">
                  <h5 class="subtitle-1 font-weight-bold">
                    {{ 0 | float }}
                    <span class="primary--text ms-2">{{ $t("egp") }}</span>
                  </h5>
                </v-col>
                <v-col cols="8" class="pb-0">
                  <h5 class="subtitle-1 font-weight-regular gray7--text">
                    {{ $t("total without vat") }}
                  </h5>
                </v-col>
                <v-col cols="4" class="pb-0 text-end">
                  <h5 class="subtitle-1 font-weight-bold text-end">
                    {{ cart.total_without_vat | float }}<span class="primary--text ms-2">{{ $t("egp") }}</span>
                  </h5>
                </v-col>
                <v-col cols="8">
                  <h5 class="subtitle-1 font-weight-regular gray7--text">
                    {{ $t("vat") }} ({{ 0 }}%)
                  </h5>
                </v-col>
                <v-col cols="4">
                  <h5 class="subtitle-1 font-weight-bold text-end">
                    {{ cart.total_vat | float }}<span class="primary--text ms-2">{{ $t("egp") }}</span>
                  </h5>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-divider />
                </v-col>
                <v-col cols="12" class="pb-5">
                  <v-btn color="primary" :disabled="cart.total_quantity == 0" :to="`/${$i18n.locale}/checkout`" x-large
                    block height="58" class="shadow font-weight-bold rounded-0" :loading="isLoading">
                    {{ $t("continue to checkout") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </section>
</template>




<script>
import ProductCardTwo from "@/components/ui/ProductCardTwo.vue";
import TextInput from "@/components/inputs/TextInput.vue";

export default {
  name: "CartPage",
  components: {
    ProductCardTwo,
    TextInput,
  },

  data: () => ({
    coupon_code: null,
    isCouponLoading: null,
    isLoading: false,
  }),
  head() { },
  mounted() {

  },
  watch: {
    cart() {

    },
    coupon_code() {
      this.$store.dispatch("setPromoCode", false);
    }
  },
  computed: {
    cart() {
      return this.$store.getters.getAllCart
    }
  },
  methods: {
    applyCode() {
      if (this.coupon_code) {
        this.isCouponLoading = true;
        this.$api.GET_METHOD(`get_promo_code?promo_code=${this.coupon_code}`).then(response => {
          this.isCouponLoading = false;
          if (response) {
            response.data.promo_code.coupon_code = this.coupon_code;
            this.$store.dispatch("setPromoCode", response.data.promo_code);
            this.$store.state.snackbarType = "primary";
            this.$store.state.showSnackbar = true;
            this.$store.state.snackbarMessages = [];
            this.$store.state.snackbarTitle = response.message;
          } else {
            this.$store.dispatch("setPromoCode", false);
          }
        })
      }
    },
    showProduct({ brand_name, name, id, product_id }) {
      let route = this.$global.RouteParam(`/${this.$i18n.locale}/products/${brand_name ? brand_name : 'brand'}/${name.replaceAll('/', '-')}?id=${product_id || id}`)
      return route;
    },
    pay() {
      this.$router.push(`/checkout/done`);
    },
  },
};
</script>

<style lang="scss" scoped>
#cart-page {
  background: var(--white);
  min-height: 100vh;
  width: 100vw;

  .text-h4 {
    font-weight: 600;
    font-size: 24px !important;
    color: var(--gray2);
  }

  .text-h5 {
    font-weight: 600;
    font-size: 20px !important;
    color: var(--gray2);
  }

  .text-h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    color: var(--gray7);
  }
}
</style>
