<template>
  <section id="auth-page">
    <!-- breadcrumb -->
    <v-card flat color="light" class="rounded-0">
      <v-container>
        <h3 class="text-h6 font-weight-bold text-uppercase">{{ $t('my account') }}</h3>
        <h6 class="subtitle-2 text-capitalize">
          {{ $t('home') }}
          <v-icon size="20">{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
          {{ $t('my account') }}
        </h6>
      </v-container>
    </v-card>


    <v-container fluid class="px-2 px-md-4 px-lg-8">
      <v-form ref="form" v-model="valid">
        <v-row justify="center">
          <v-col cols="11" sm="8" md="7" lg="5" xl="4">
            <v-card min-height="360" outlined class="pa-5 ">
              <v-tabs color="#000" hide-slider v-model="tab" centered>
                <v-tab class="text-h6 font-weight-black" :class="{ 'opacity-50': tab !== 0 }">
                  {{ $t('login') }}
                </v-tab>
                <v-tab class="text-h6 font-weight-black" :class="{ 'opacity-50': tab !== 1 }">
                  {{ $t('Signup') }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <!-- Login -->
                <v-tab-item>
                  <v-card flat class="pa-5 transparent" min-height="360">
                    <v-row>
                      <GenericInput type="email" :keydownEnter="login" :value="form.email" @input="form.email = $event"
                        paddingY="py-2" :dense="false" label="email address" :required="true" :isLoading="false"
                        :cols="[12, 12, 12]" />

                      <GenericInput type="password" :keydownEnter="login" :value="form.password"
                        @input="form.password = $event" paddingY="py-2" :dense="false" label="password" :required="true"
                        :isLoading="false" :cols="[12, 12, 12]" />

                      <v-col cols="12">
                        <router-link to="/forget">{{ $t('forget your password ?') }}</router-link>
                        <v-btn color="primary" class="mt-3" depressed block x-large height="49" @click="login"
                          :loading="isLoading">
                          {{ $t('login') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>

                <!-- Register -->
                <v-tab-item>
                  <v-card flat class="pa-5 transparent" min-height="360">
                    <v-row>
                      <GenericInput type="text" :keydownEnter="register" :value="form.name" @input="form.name = $event"
                        paddingY="py-2" :dense="false" label="full name" :required="true" :isLoading="false"
                        :cols="[12, 6, 6]" />

                      <GenericInput type="email" :keydownEnter="register" :value="form.email" @input="form.email = $event"
                        paddingY="py-2" :dense="false" label="email address" :required="true" :isLoading="false"
                        :cols="[12, 6, 6]" />

                      <GenericInput type="password" :keydownEnter="register" :value="form.password"
                        @input="form.password = $event" paddingY="py-2" :dense="false" label="password" :required="true"
                        :isLoading="false" :cols="[12, 6, 6]" />

                      <GenericInput type="password" :keydownEnter="register" :value="form.confirm_password"
                        @input="form.confirm_password = $event" paddingY="py-2" :dense="false" label="confirm password"
                        :required="true" :isLoading="false" :cols="[12, 6, 6]" />

                      <v-col cols="12" class="mt-9">
                        <v-btn color="primary" depressed block x-large height="49" @click="register" :loading="isLoading">
                          {{ $t('create an account') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>

              <v-col cols="12" class="pt-0">
                <v-divider class="" />
                <div class="white mt-n3 mb-3 text-center text-uppercase or">{{ $t('or') }}</div>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn color="blue4" @click="social(1)" class="blue12" outlined block x-large height="49"
                      :loading="isLoading">
                      <v-icon left>mdi-facebook</v-icon>
                      {{ $t('login with facebook') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-btn color="red4" @click="social(2)" class="red12" outlined block x-large height="49"
                      :loading="isLoading">
                      <v-icon left>mdi-google</v-icon>
                      {{ $t('login with google') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

  </section>
</template>




<script>
import GenericInput from '@/components/ui/GenericInput.vue';



export default {
  name: "AuthenticationPage",
  components: {
    GenericInput
  },

  data: () => ({
    tab: 0,
    valid: false,
    isLoading: false,
    form: {
      email: null,
      password: null,
      confirm_password: null,
      name: null,
    }
  }),
  head() { },
  mounted() {
    if (this.$store.state.isAuth) {
      this.$router.push(`/${this.$i18n.locale}/profile`)
    }
  },
  watch: {

  },
  computed: {

  },
  methods: {
    login() {
      if (this.valid) {
        this.isLoading = true;
        this.$api.POST_METHOD(`login`, this.form).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.$api.SET_AUTH(response?.data?.data);
          } else {
            this.$api.ShowModal('error', response.msg);
          }
        })
      } else {
        this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
      }
    },
    register() {
      if (this.valid) {
        if (this.form.password == this.form.confirm_password) {
          this.isLoading = true;
          this.$api.POST_METHOD(`register`, this.form).then((response) => {
            this.isLoading = false;
            if (response.check) {
              this.$api.SET_AUTH(response?.data?.data);
            } else {
              this.$api.ShowModal('error', response.msg);
            }
          })
        } else {
          this.$api.ShowModal('warning', this.$i18n.t('password not matched'));
        }
      }
      else {
        this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
      }
    },
    social() {
      this.$store.state.userData = {
        name: 'user name',
        email: 'user email',
        mobile: '01234567xxx',
      }
      this.$store.state.isAuth = true;
      this.$router.push(`/${this.$i18n.locale}/profile`)
    }
  },
};
</script>

<style lang="scss" scoped>
#auth-page {
  .or {
    width: 60px;
    margin: auto;
  }
}
</style>
