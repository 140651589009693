<template>
  <v-card color="white" relative class="shadow-none rounded-0 d-none d-lg-block">
    <v-container>
      <v-row justify="space-between" align="center">
        <v-col cols="auto">
          <v-row>
            <v-col cols="auto" class="py-2">
              <v-btn plain color="gray2" class="font-weight-bold" active-class="activeLink" :to="`/${$i18n.locale}/`"
                exact>
                {{ $t("home") }}</v-btn>
            </v-col>
            <v-col cols="auto" class="py-2">
              <v-btn plain color="gray2" class="font-weight-bold" active-class="activeLink"
                :to="`/${$i18n.locale}/products`">
                {{ $t("products") }}</v-btn>
            </v-col>
            <v-col cols="auto" class="py-2" v-for="(department, index) in departments" :key="index">
              <v-btn plain color="gray2" class="font-weight-bold" active-class="activeLink"
                :to="`/${$i18n.locale}/products?department=${department.id}`">
                {{ department.name }}</v-btn>
            </v-col>
            <v-col cols="auto" class="py-2">
              <v-btn plain color="gray2" class="font-weight-bold" active-class="activeLink"
                :to="`/${$i18n.locale}/registration`">
                {{ $t("signup") }}</v-btn>
            </v-col>
            <v-col cols="auto" class="py-2">
              <v-btn plain color="gray2" class="font-weight-bold" active-class="activeLink"
                :to="`/${$i18n.locale}/authentication`">
                {{ $t("my account") }}</v-btn>
            </v-col>
            <v-col cols="auto" class="py-2">
              <v-btn plain color="gray2" class="font-weight-bold" active-class="activeLink"
                :to="`/${$i18n.locale}/blogs`">
                {{ $t("blogs") }}</v-btn>
            </v-col>
            <v-col cols="auto" class="py-2">
              <v-btn plain color="gray2" class="font-weight-bold" active-class="activeLink"
                :to="`/${$i18n.locale}/about`">
                {{ $t("about us") }}</v-btn>
            </v-col>
            <v-col cols="auto" class="py-2">
              <v-btn plain color="gray2" class="font-weight-bold" active-class="activeLink"
                :to="`/${$i18n.locale}/contact`">
                {{ $t("contact us") }}</v-btn>
            </v-col>
            <v-col cols="auto" class="py-2">
              <v-btn plain color="gray2" class="font-weight-bold" @click="changeLang">
                {{ $i18n.locale == 'en' ? 'عربي' : 'English' }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="opacity-50" />
  </v-card>
</template>

<script>
export default {
  name: "ThirdBar",
  components: {},
  computed: {
    languageRoute() {
      return this.$route.fullPath
    },
    departments() {
      return this.$store.getters.homePage.departments || [];
    },
  },
  data: () => ({}),
  watch: {},
  mounted() { },
  methods: {
    changeLang() {
      let current_path = this.$route.fullPath
      let change_path = current_path.replace(this.$i18n.locale, this.$i18n.locale === "en" ? "ar" : "en")
      localStorage.setItem('language', this.$i18n.locale === "en" ? "ar" : "en");
      this.$i18n.locale = this.$i18n.locale === "en" ? "ar" : "en";
      this.$vuetify.rtl = this.$i18n.locale === "ar";
      this.$router.push(change_path);
    },

  },
};
</script>
<style scoped></style>
