<template>
  <section class="productsRowSection">
    <v-container>
      <v-col cols="12" class="mb-3">
        <h3 class="text-h5 text-center"> {{ title }}</h3>
        <v-divider />
      </v-col>
      <v-row justify="center" align="center" class="mb-3" v-if="type == 1">
        <v-col cols="auto" v-for="(group, index) in groups" :key="index">
          <v-btn text class="text-uppercase" :color="activeCategory == index ? 'gray1' : 'gray9'"
            @click="activeCategory = index">
            {{ group.name }}</v-btn>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" v-if="type !== 2">
        <v-col cols="6" sm="auto" v-for="(product, index) in activeProducts" :key="index">
          <ProductCard :width="`${cardWidth}px`" cardMargin="0" :product="product" :cardClicked="showProduct" />
        </v-col>
      </v-row>

      <v-row justify="space-between" v-if="type == 2">
        <v-col cols="12" lg="6" class="d-none d-md-block">
          <ProductCard :width="`100%`" :imgHeight="`644px`" cardMargin="0" :product="products[0]"
            :cardClicked="showProduct" />
        </v-col>
        <v-col cols="12" lg="6">
          <v-row justify="center" align="center">
            <v-col cols="6" sm="auto" class="d-md-none">
              <ProductCard :width="`${cardWidth}px`" cardMargin="0" :product="products[0]" :cardClicked="showProduct" />
            </v-col>
            <v-col cols="6" sm="auto" v-for="(product, index) in products.slice(1).slice(0, 4)" :key="index">
              <ProductCard :width="`${cardWidth}px`" cardMargin="0" :product="product" :cardClicked="showProduct" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="products.slice(5).length">
          <v-row justify="center" align="center">
            <v-col cols="6" sm="auto"  v-for="(product, index) in products.slice(5)" :key="index">
              <ProductCard :width="`${cardWidth}px`" cardMargin="0" :product="product" :cardClicked="showProduct" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-container>
  </section>
</template>

<script>
import ProductCard from '@/components/ui/ProductCard.vue';
export default {
  name: "ProductsSliderSection",
  components: { ProductCard },
  data: () => ({
    activeCategory: 0,
  }),
  props: {
    products: { default: [] },
    groups: { default: [] },
    type: { default: 1 },
    title: { default: null },
    show_route: { default: null },
  },
  watch: {

  },
  mounted() {

  },
  computed: {
    cardWidth() {
      if (this.$vuetify.breakpoint.xl) {
        return 250;
      }
      else if (this.$vuetify.breakpoint.lg) {
        return 250;
      }
      else if (this.$vuetify.breakpoint.md) {
        return 250;
      }
      if (this.$vuetify.breakpoint.sm) {
        return 250;
      }
      else { return this.$store.state.windowSize.x - 60; }
    },
    activeProducts() {
      if (this.groups.length) {
        return this.groups.at(this.activeCategory)?.products
      } else {
        return this.products
      }
    }

  },
  methods: {

    showProduct({ brand_name, name, id }) {
      let route = this.$global.RouteParam(`/${this.$i18n.locale}/products/${brand_name ? brand_name : 'brand'}/${name.replaceAll('/', '-')}?id=${id}`)
      return route;
    },
  }
}
</script>

<style lang="scss" scoped>
.productsRowSection {
  position: relative;
  margin-bottom: 60px;

  .text-h5 {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--gray3);
  }

  .v-divider {
    position: relative;
    width: 50px;
    margin: auto;
    margin-top: 10px;
    display: block;

    &::after {
      content: '';
      background-color: var(--gray9);
      width: 50px;
      height: 3px;
      position: absolute;
      left: 0;
      top: -2px;
      border-radius: 25px;
    }
  }

}
</style>