<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <h6 class="subtitle-1">{{ $t('individual account') }}</h6>
      </v-col>
      <!-- first tab -->
      <GenericInput type="text" :value="form.full_name" @input="form.full_name = $event" paddingY="py-3"
        label="Full Name (As in National ID)" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" />

      <GenericInput type="text" :value="form.mobile" @input="form.mobile = $event" paddingY="py-3" label="Mobile Phone"
        :required="true" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" />

      <GenericInput type="text" :value="form.whatsapp" @input="form.whatsapp = $event" paddingY="py-3" label="whatsapp"
        :required="true" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" />

      <GenericInput type="email" :value="form.email" @input="form.email = $event" paddingY="py-3" label="E-mail Address"
        :required="true" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" />

      <GenericInput type="date" :value="form.date_of_barth" @input="form.date_of_barth = $event" paddingY="py-3"
        label="Date of Birth (at least 21y)" :required="true" :isLoading="false" :cols="[12, 12, 12]" hint=""
        :inputDesign="inputDesign" />


      <GenericInput type="text" :value="form.profile_link" @input="form.profile_link = $event" paddingY="py-3"
        label="Facebook/ Instagram/ Linkedin In Personal Profile Link" :required="true" :isLoading="false"
        :cols="[12, 12, 12]" :inputDesign="inputDesign" />

      <GenericInput type="select" paddingY="py-3" :lookups="cameras" :multi="true" :required="true" :value="form.camera"
        @input="form.camera = $event" label="Camera System Used" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" :has_slot="true">
        <template v-slot:selection="item">
          <v-chip> {{ item.item }}</v-chip>
        </template>
        <template v-slot:item="item">
          <v-icon class="me-2" color="primary">
            {{ form.camera.some(camera => camera == item.item) ? 'mdi-checkbox-marked' :
              'mdi-checkbox-blank-outline' }}
          </v-icon>
          {{ item.item }}
        </template>
      </GenericInput>

      <GenericInput type="radiogroup" paddingY="py-3" :lookups="hearingTypes" selected_label="name" selected_prop="name"
        :required="true" :value="form.hearing_type" @input="form.hearing_type = $event" label="How did you hear about us"
        :isLoading="false" :cols="[12, 12, 12]" />

      <!-- second tab -->
      <v-col cols="12" class="py-2 px-16 mt-3">
        <v-divider />
      </v-col>

      <GenericInput type="radiogroup" paddingY="py-3" :lookups="governments" selected_label="name" selected_prop="name"
        :required="true" :value="form.government" @input="form.government = $event" label="Home Address"
        :inputDesign="inputDesign" :isLoading="false" :cols="[12, 12, 12]" />


      <GenericInput type="select" paddingY="py-3" :lookups="cities" :multi="false" :required="true" :value="form.city"
        :selected_label="`city_name_${$i18n.locale}`" :selected_prop="`city_name_${$i18n.locale}`"
        @input="form.city = $event" label="city" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign"
        :has_slot="false" />

      <GenericInput type="text" :value="form.street" @input="form.street = $event" paddingY="py-3" label="Street Name"
        :required="true" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" />

      <GenericInput type="text" :value="form.building" @input="form.building = $event" paddingY="py-3" label="Building"
        :required="true" :isLoading="false" :cols="[12, 6, 4]" inputDesign="up_label" />

      <GenericInput type="text" :value="form.floor" @input="form.floor = $event" paddingY="py-3" label="Floor"
        :required="true" :isLoading="false" :cols="[12, 6, 4]" inputDesign="up_label" />

      <GenericInput type="text" :value="form.apartment" @input="form.apartment = $event" paddingY="py-3" label="Apartment"
        :required="true" :isLoading="false" :cols="[12, 6, 4]" inputDesign="up_label" />



      <!-- third tab -->
      <v-col cols="12" class="py-2 px-16 mt-3">
        <v-divider />
      </v-col>

      <GenericInput type="select" paddingY="py-3" :lookups="categories" :multi="false" :required="true"
        :value="form.category" :selected_label="`name`" :selected_prop="`name`" @input="form.category = $event"
        label="category" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign" :has_slot="false" />


      <GenericInput type="text" :value="form.portfolio_link" @input="form.portfolio_link = $event" paddingY="py-3"
        label="portfolio link" :required="true" :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign"
        hint="( Linkedin - Bechance - Instagram )" />

      <GenericInput type="file" :value="form.id_card_front" @input="form.id_card_front = $event" paddingY="py-3"
        :multi="false" label="Upload Your National ID (Front)" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" hint="(PNG - JPG - PDF)" />

      <GenericInput type="file" :value="form.id_card_back" @input="form.id_card_back = $event" paddingY="py-3"
        :multi="false" label="Upload Your National ID (Rear)" :required="true" :isLoading="false" :cols="[12, 12, 12]"
        :inputDesign="inputDesign" hint="(PNG - JPG - PDF)" />


      <GenericInput type="file" :base64="false" :multi="false" :value="form.second_id_card"
        @input="form.second_id_card = $event" paddingY="py-3" label="another identification document" :required="true"
        :isLoading="false" :cols="[12, 12, 12]" :inputDesign="inputDesign"
        hint="( passport - driver's license - university ID .... )" />

      <v-col cols="12" class="d-flex justify-end">
        <v-btn class="text-uppercase rounded-0 subtitle-1" :disabled="!(valid)" depressed large color="primary"
          :loading="isLoading" @click="sendForm">
          {{ $t('registration') }}
        </v-btn>
      </v-col>

    </v-row>
  </v-col>
</template>


<script>
export default {
  name: "IndividualForm",
  props: {
    accountTypes: { default: [] },
    hearingTypes: { default: [] },
    governments: { default: [] },
    categories: { default: [] },
    cities: { default: [] },
    cameras: { default: [] },
    form: { default: {} },
    valid: { default: false },
    isLoading: { default: false },
    inputDesign: { default: 'inline_label' },
  },
  data: () => ({
    step: 1,
  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    sendForm() {
      if (this.valid) {
        this.isLoading = true;
        this.$api.POST_METHOD_MULTIPART(`register_form_post`, this.form).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.$store.state.snackbarTime = 8500;
            this.$router.push(`/${this.$i18n.locale}/`)
          } else {
            this.$api.ShowModal('error', response.msg);
          }
        })
      } else {
        this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
      }
    }
  },
};
</script>



<style scoped lang="scss"></style>
