<template>
  <v-menu offset-y transition="slide-y-transition" bottom max-width="620" v-model="menu" :open-on-hover="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'"
    :close-on-content-click="false">
    <template #activator="{ on, attrs }">
      <button  class="d-flex align-center" v-on="on" v-bind="attrs" @click="showCart">
        <div class="me-3 d-none d-md-block subtitle-2">
          <div>{{ $t('your cart') }}</div>
          <div class="font-weight-bold text-end text-uppercase">{{ cart.products.length }} {{ $t('item') }}</div>
        </div>
        <img loading="lazy" src="@/assets/img/png/cart.png" height="25" alt="Pixipine" />

      </button>
    </template>

    <v-card elevation="0" class="pa-3" v-if="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
      <v-col cols="12">
        <h6 class="pa-0 text-h6">
          {{ cart.total_quantity ? $t("your cart") : $t("your cart is empty") }}
        </h6>
      </v-col>
      <v-col cols="12" class="py-0" v-if="!cart.total_quantity">
        <p class="subtitle-1 text-center" @click="menu = false">
          <router-link class="primary--text" :to="`/products`">
            {{ $t("see our items") }}
          </router-link>
        </p>
      </v-col>

      <v-card width="540"  v-if="cart.total_quantity" class="pa-0 elevation-0 transparent overflow-y-auto"
        :style="{ maxHeight: $store.state.windowSize.y - 360 + 'px' }">
        <v-col cols="12" class="pt-0" v-for="(product, index) in cart.products" :key="index">
          <ProductCardTwo imgHeight="100px" :product="product" :cart="true" :index="index"  :cardClicked="showProduct" />
        </v-col>
      </v-card>

      <v-col cols="12" v-if="cart.total_quantity" class="d-flex py-2">
        <v-col cols="8" class="py-0">
          <h5 class="text-h5">{{ $t("total payment") }}</h5>
        </v-col>
        <v-col cols="4" class="py-0">
          <h5 class="text-h5 text-end">
            {{ cart.total_after_discount | float }}
            <span class="primary--text">{{ $t("egp") }}</span>
          </h5>
        </v-col>
      </v-col>
      <v-col cols="12" v-if="cart.total_quantity">
        <v-btn color="primary" x-large @click="showCheckout" class="rounded-0 shadow" block>
          {{ $t("continue to checkout") }}
        </v-btn>
      </v-col>
    </v-card>
  </v-menu>
</template>

<script>
import ProductCardTwo from "./ProductCardTwo.vue";

export default {
  name: "CartMenu",
  components: { ProductCardTwo },
  data: () => ({
    menu: false,
    subtotal: 0,
  }),
  computed: {
    cart() {
      return this.$store.getters.getAllCart
    }
  },
  watch: {},
  mounted() { },
  methods: {
    showCart() {
      if(this.cart.total_quantity){
        this.$router.push(`/cart`)
      }
    },
    showCheckout() {
      if(this.cart.total_quantity){
        this.$router.push(`/checkout`)
      }
    },
    showProduct({ brand_name, name, id , product_id }) {
      let route = this.$global.RouteParam(`/${this.$i18n.locale}/products/${brand_name ? brand_name : 'brand'}/${name.replaceAll('/', '-')}?id=${product_id || id}`)
      return route;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-h5 {
  font-weight: 600;
  font-size: 24px !important;
  line-height: 36px;
  color: var(--gray2);
}
</style>
