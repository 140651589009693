<template>
  <section>
    <!-- breadcrumb -->
    <v-card flat color="light" class="rounded-0">
      <v-container>
        <h3 class="text-h6 font-weight-bold text-uppercase">{{ $t('products page') }}</h3>
        <h6 class="subtitle-2 text-capitalize">
          {{ $t('home') }}
          <v-icon size="20">{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
          {{ $t('products') }}
        </h6>
      </v-container>
    </v-card>

    <v-container fluid>
      <!-- list view -->
      <v-card outlined class="pa-5 py-3 rounded-0">
        <v-row justify="center" justify-sm="space-between">
          <v-col cols="auto" class="d-flex align-center">
            <div class="subtitle-2 me-2">{{ $t('shop by') }}</div>
            <v-btn icon :color="viewType == 'small' ? 'primary' : 'gray7'" @click="viewType = 'small'">
              <v-icon>mdi-view-grid-outline</v-icon>
            </v-btn>
            <v-btn icon :color="viewType == 'large' ? 'primary' : 'gray7'" @click="viewType = 'large'">
              <v-icon> mdi-view-list</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="d-flex justify-center align-center">
            <div class="subtitle-2 me-2">{{ $t('sort by') }}</div>
            <AutoCompleteInput background="light"
              :lookups="[{ name: $i18n.t('low to height'), id: 'des' }, { name: $i18n.t('height to low'), id: 'asc' }]"
              selected_label="name" selected_prop="id" :value="sortBy" @input="sortBy = $event"
              label="choose from the list" />
            <v-btn color="primary" class="ms-2 d-md-none" @click="filterDialog = true" icon>
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="mt-2">
        <!-- filter -->
        <v-col cols="12" md="4" lg="3" class="d-none d-md-block">
          <FilterCard :categories="categories" :activeCategory="activeCategory" :departments="departments"
            :activeDepartment="activeDepartment" :brands="brands" :activeBrand="activeBrand" :setActive="setActive"
            :clearFilter="clearFilter" />
        </v-col>
        <v-col cols="12" md="8" lg="9" class="py-0">
          <!-- List -->
          <v-row align="center" :justify="'center'" class="mt-2">
            <v-col :cols="'6'" :sm="viewType == 'small' ? 'auto' : 12" :md="viewType == 'small' ? 4 : 12"
              :lg="viewType == 'small' ? 3 : 6" v-for="(product, index) in products" :key="index">
              <ProductCard v-if="viewType == 'small'" :width="`250px`" cardMargin="0" :product="product"
                :cardClicked="showProduct" />
              <ProductCardTwo v-else :width="`500px`" cardMargin="0" :product="product" :cardClicked="showProduct" />
            </v-col>
            <v-col cols="12" class="pa-0" v-intersect="onIntersect" v-if="!no_more">
              <v-row>
                <v-col cols="11" sm="6" md="4" lg="3" xl="3" class="my-5" v-for="(index) in 4" :key="index">
                  <v-sheet class="pa-3">
                    <v-skeleton-loader class="mx-auto" max-width="260" type="card" />
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" v-if="!products.length && !isLoading" class="pt-0">
              <v-alert outlined color="gray9" class="shadow rounded-0">
                <div class="gray4--text font-weight-bold text-center"> {{ $t('no products here') }}</div>
              </v-alert>
            </v-col>
            <v-col cols="12" class="my-6" v-if="no_more && !isLoading && products.length !== 0">
              <h5 class="text-h5 text-center primary--text font-weight-bold">{{ $t("you have seen all") }} {{
                $t("our products") }}</h5>
            </v-col>
          </v-row>
        </v-col>
      </v-row>


    </v-container>


    <v-dialog v-model="filterDialog" overlay-opacity="0.75" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card relative class="pa-16">
        <div class="py-0 px-4">

          <FilterCard :categories="categories" :activeCategory="activeCategory" :departments="departments"
            :activeDepartment="activeDepartment" :brands="brands" :activeBrand="activeBrand" :setActive="setActive"
            :clearFilter="clearFilter" />
          <v-row justify="center" class="white shadow mt-2"
            style="position: sticky !important; bottom : 0; z-index: 555;">
            <v-col cols="12">
              <v-btn color="primary" block @click="filterDialog = false" class="rounded-lg" depressed>
                {{ $t('apply filter') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn color="error" block text @click="clearFilter" class="rounded-lg" depressed>
                {{ $t('clear filter') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>

      </v-card>
    </v-dialog>

  </section>
</template>


<script>
import ProductCard from '@/components/ui/ProductCard.vue';
import ProductCardTwo from '@/components/ui/ProductCardTwo.vue';
import FilterCard from '@/components/ui/FilterCard.vue';
export default {
  name: "ProductsList",
  components: {
    ProductCard, ProductCardTwo, FilterCard
  },
  data: () => ({
    viewType: 'small',
    page: 1,
    pagination: {
      page: 1,
      totalPages: 1,
      per_page: 8,
      totalRows: 0,
    },
    isLoading: false,
    no_more: false,
    filterDialog: false,
    sortBy: 0,
    activeCategory: 0,
    activeBrand: 0,
    activeDepartment: 0,
    categories: [],
    brands: [],
    departments: [],
    products: [],
  }),
  watch: {
    $route() {
      this.queryParams();
      this.getProducts();
    },
    activeCategory() {
      if (!this.isLoading)
        this.changeParam();
    },
    activeBrand() {
      if (!this.isLoading)
        this.changeParam();
    },
    activeDepartment() {
      if (!this.isLoading)
        this.changeParam();
    },
    sortBy() {
      if (!this.isLoading)
        this.changeParam();
    },
  },
  created() {

  },
  destroyed() {

  },
  computed: {

  },
  mounted() {
    this.queryParams();
    // this.getProducts();
  },
  methods: {
    queryParams() {
      // this.page = +this.$router.currentRoute.query.page || 1;
      this.page = 1;
      this.no_more = false;
      this.activeDepartment = +this.$router.currentRoute.query.department || 0;
      this.activeBrand = +this.$router.currentRoute.query.brand || 0;
      this.activeCategory = +this.$router.currentRoute.query.category || 0;
      this.products = [];
    },
    onIntersect() {
      if (!this.isLoading)
        this.getProducts();
    },
    getProducts() {
      this.isLoading = true;
      let queryParam = `?page=${this.page || 1}&sort=${this.sortBy || ''}&category=${this.activeCategory || ''}&brand=${this.activeBrand || ''}&department=${this.activeDepartment || ''}&rows=8`
      this.$api.GET_METHOD(`product${queryParam}`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.brands = response.data.brands || [];
          this.categories = response.data.categorys || [];
          this.departments = response.data.departments || [];
          this.page = +response.data.items.current_page;
          this.pagination = {
            page: +response.data.items.current_page,
            totalPages: +response.data.items.last_page,
            per_page: +response.data.items.per_page,
            totalRows: +response.data.items.total,
          };
          if (response.data.items.data.length > 0) {
            this.products.push(...response.data.items.data);
            this.page = this.page + 1
          } else {
            this.no_more = true
          }
        }
      })
    },
    changeParam() {
      let queryParam = `?sort=${this.sortBy || ''}&category=${this.activeCategory || ''}&brand=${this.activeBrand || ''}&department=${this.activeDepartment || ''}`
      this.$router.push('/' + this.$i18n.locale + '/products' + queryParam)
    },
    showProduct({ brand_name, name, id }) {
      let route = this.$global.RouteParam(`/${this.$i18n.locale}/products/${brand_name ? brand_name : 'brand'}/${name.replaceAll('/', '-')}?id=${id}`)
      return route;
    },
    setActive(activeDepartment, activeCategory, activeBrand) {
      this.activeDepartment = activeDepartment
      this.activeCategory = activeCategory
      this.activeBrand = activeBrand
    },
    clearFilter() {
      this.page = 1;
      this.filterDialog = false;
      this.$router.push(`/${this.$i18n.locale}/products`)
    },
  }

};
</script>


<style lang="scss" scoped></style>