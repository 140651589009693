<template>
  <section>
    <v-row :justify="type == 'center' ? 'center' : 'space-between'" align="center">
      <v-col cols="auto" sm="auto">
        <h3 class="text-h5" :class="{ 'text-center': type == 'center' }">
          {{ $t(title) }}
        </h3>
      </v-col>
      <v-col cols="auto" sm="auto" v-if="show_route">
        <v-btn text :to="`/${this.$i18n.locale}/${show_route}`"><span class="">{{ $t("view all") }}</span>
          <img loading="lazy" class="ms-2" height="16" v-if="$i18n.locale == 'ar'" src="@/assets/img/svg/chevron-left.svg" alt="Pixipine" />
          <img loading="lazy" class="ms-2" height="16"  v-else src="@/assets/img/svg/chevron-right.svg" alt="Pixipine" />
        </v-btn>
      </v-col>
      <v-col cols="12" class="py-1 pe-16" :class="{ 'pe-0': type == 'center' }">
        <v-divider :class="{ center: type == 'center' }" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "RowHeader",
  components: {},
  data: () => ({}),
  props: {
    title: { default: null },
    show_route: { default: null },
    type: { default: null },
  },
  watch: {},
  mounted() { },
  methods: {},
};
</script>

<style lang="scss" scoped>
.text-h5 {
  text-transform: capitalize;
  font-weight: bold;
  color: var(--gray3);

}

.v-divider {
  position: relative;

  &::after {
    content: "";
    background-color: var(--accent);
    width: 200px;
    height: 3px;
    position: absolute;
    left: 0;
    top: -2px;
    border-radius: 25px;
  }

  &.center::after {
    left: 15%;
    right: 15%;
    width: 70%;
  }
}

.v-application--is-rtl {
  .v-divider {
    &::after {
      left: unset;
      right: 0;
    }

    &.center::after {
      left: 15%;
      right: 15%;
      width: 70%;
    }
  }
}
</style>
