<template>
  <div class="d-flex align-center searchInput">
    <v-autocomplete v-model="select" clearable color="primary" dense :loading="isLoading" :items="items"
      :search-input.sync="search" item-text="name" item-value="id" flat hide-no-data hide-details
      :placeholder="$i18n.t('search something')" :no-data-text="$i18n.t('no search result')" outlined return-object
      @keydown.enter="fetchData()" @click:clear="items = []">
      <template v-slot:item="{ item }">
        <div class="subtitle-2 pa-1 d-flex align-center" style="width : 100%">
          <img loading="lazy" width="50" class="me-2" v-if="resultImage"
            :src="item.image ? $store.state.endpointURL + item.image : require('@/assets/img/png/icon.png')"
            :alt="item.name" :title="item.name">
          <div>
            <div>{{ item.name }}</div>
            <v-chip x-small :color="item.color" class="me-2">{{ item.brand_name }}</v-chip>
          </div>
        </div>
      </template>
    </v-autocomplete>
    <v-icon v-if="isLoading" color="primary" class="spinner appendIcon">mdi-loading</v-icon>
    <!-- <img loading="lazy" src="@/assets/img/svg/search.svg" @click="fetchData" v-else class="cursor_pointer primary appendIcon"
       alt="Pixipine" /> -->
      <v-icon class="primary cursor_pointer appendIcon" @click="fetchData" v-else style="position: relative;z-index: 5555;">mdi-magnify</v-icon>
  </div>
</template>


<script>
export default {
  name: "SearchInput",
  data: () => ({
    search: null,
    select: null,
    isLoading: false,
    items: [],
  }),
  props: {
    resultImage: { default: true },
  },
  watch: {
    search(val) {
      if (this.search) {
        setTimeout(() => {
          if (this.search === val) {
            this.fetchData(val)
          }
        }, 750);
      } else {
        this.items = [];
      }
    },
    select() {
      if (this.select) {
        this.showItem(this.select)
      }
    },
  },
  computed: {

  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`product?word=${this.search}`).then(response => {
        this.isLoading = false;
        if (response) {
          this.items = [...response.data.items];
        }
      })
    },
    showItem({ brand_name, name, id }) {
      let route = this.$global.RouteParam(`/${this.$i18n.locale}/products/${brand_name ? brand_name : 'brand'}/${name.replaceAll('/', '-')}?id=${id}`)
      this.$router.push(route);
    },
  }

};
</script>



<style scoped lang="scss">
.appendIcon {
  position: absolute !important;
  right: 0;
  top: 0;
  height: 38px;
  z-index: 20;
  width: 38px;
  color: #fff;
}

.v-application--is-rtl {
  .appendIcon {
    left: 0;
    right: unset;
  }
}
</style>
