<template>
  <section>
    <!-- breadcrumb -->
    <v-card flat color="light" class="rounded-0">
      <v-container>
        <h3 class="text-h6 font-weight-bold text-uppercase">{{ $t('client registration form') }}</h3>
        <h6 class="subtitle-2 text-capitalize">
          {{ $t('home') }}
          <v-icon size="20">{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
          {{ $t('client registration form') }}
        </h6>
      </v-container>
    </v-card>
    <v-container class="my-2">
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" md="5" class="body-2">
            {{ $t('registrationText') }}
          </v-col>
          <v-col cols="12" md="7">
            <v-row justify="start">
              <v-col cols="12">
                <h6 class="subtitle-1">
                  {{ $t('account type') }}
                </h6>
              </v-col>
              <v-col cols="12" class="py-0">
                <p class="body-2">
                  <span class="font-weight-black me-1">{{ $t('individual account') }} : </span>
                  {{ $t('individualText') }}
                </p>
                <p class="body-2">
                  <span class="font-weight-black me-1">{{ $t('corporate account') }} : </span>
                  {{ $t('corporateText') }}
                </p>
                <p class="body-2">
                  <span class="font-weight-black me-1">{{ $t('studio account') }} : </span>
                  {{ $t('studioText') }}
                </p>
              </v-col>

              <GenericInput type="radiogroup" paddingX="px-0" paddingY="py-0" :lookups="accountTypes"
                selected_label="name" selected_prop="id" :value="form.account_type" @input="form.account_type = $event"
                label=" " :isLoading="false" :cols="[12, 12, 12]" />

              <v-col cols="12" class="py-2 px-16 mt-3">
                <v-divider />
              </v-col>

              <!-- Individual -->
              <IndividualForm :form="form" v-if="form.account_type === 1" :valid="valid" :accountTypes="accountTypes"
                :hearingTypes="hearingTypes" :governments="governments" :cameras="cameras" :cities="cities"
                :categories="categories" :isLoading="isLoading" :inputDesign="inputDesign" />

              <!-- Corporate -->

              <CorporateForm :form="form" v-if="form.account_type === 2" :valid="valid" :accountTypes="accountTypes"
                :hearingTypes="hearingTypes" :governments="governments" :cameras="cameras" :cities="cities"
                :categories="categories" :isLoading="isLoading" :inputDesign="inputDesign" />

              <!-- Studio -->

              <StudioForm :form="form" v-if="form.account_type === 3" :valid="valid" :accountTypes="accountTypes"
                :hearingTypes="hearingTypes" :governments="governments" :cameras="cameras" :cities="cities"
                :categories="categories" :isLoading="isLoading" :inputDesign="inputDesign" />

            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </section>
</template>


<script>
import IndividualForm from '@/components/ui/IndividualForm';
import CorporateForm from '@/components/ui/CorporateForm';
import StudioForm from '@/components/ui/StudioForm';
import cities_json from '@/assets/cities.json';
export default {
  name: "RegisterFormPage",
  components: { IndividualForm, CorporateForm, StudioForm },
  mounted() {
    this.setLockups();
  },
  data: () => ({
    form: {
      account_type: 1,
      // individual
      full_name: null,
      mobile: null,
      whatsapp: null,
      email: null,
      date_of_barth: null,
      profile_link: null,
      camera: [],
      hearing_type: null,
      government: null,
      city: 1,
      area: null,
      street: null,
      building: null,
      floor: null,
      apartment: null,
      category: null,
      portfolio_link: null,
      id_card_front: null,// file
      id_card_back: null,// file
      second_id_card: null,// file
      // company
      company_name: null,
      company_address: null,
      company_phone: null,
      company_website: null,
      company_social: null,
      company_ceo_name: null,
      company_ceo_mobile: null,
      company_ceo_email: null,
      company_ceo_id_front: null,// file
      company_ceo_id_back: null,// file
      auth_person_name: null,
      auth_person_mobile: null,
      auth_person_email: null,
      auth_person_id_front: null,// file
      auth_person_id_back: null,// file
      tax_card: null, // file
      commercial_register: null,  // file
      // studio
    },
    accountTypes: [],
    hearingTypes: [],
    cameras: [],
    areas: [],
    governments: [],
    categories: [],
    isLoading: false,
    valid: false,
    step: 1,
  }),
  methods: {
    setLockups() {
      this.accountTypes = [
        { name: this.$t('individual account'), id: 1 },
        { name: this.$t('corporate account'), id: 2 },
        { name: this.$t('studio account'), id: 3 }
      ]
      this.hearingTypes = [
        { name: this.$t('pixipine.camera'), id: 1 },
        { name: this.$t('Facebook'), id: 2 },
        { name: this.$t('Instagram'), id: 3 },
        { name: this.$t('Friend Recommendation'), id: 4 },
        { name: this.$t('Training or Event'), id: 5 },
        { name: this.$t('Other'), id: 6 },
      ]
      this.governments = [
        { name: this.$t('cairo'), id: 1 },
        { name: this.$t('giza'), id: 2 },
        { name: this.$t('alexandria'), id: 3 },
      ]
      this.form.government = this.$t('cairo')
      this.categories = [
        { name: this.$t('photography') },
        { name: this.$t('videography') },
        { name: this.$t('cinemagraphy') },
        { name: this.$t('audio eng') },
        { name: this.$t('other') },
      ]
      this.cameras = ['ARRI', 'BlackMagic', 'Canon', 'FujiFilm', 'GoPro', 'JVC', 'Nikon', 'Panasonic', 'RED', 'Sony', 'Other', 'cameras 360']
      // this.cities = cities_json
    },
    sendContact() {
      if (this.valid) {
        this.isLoading = true;
        this.$api.POST_METHOD(`register_form_post`, this.form).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.$api.ShowModal('success', response.msg);
            this.form = {
              account_type: 1,
              full_name: null,
              mobile: null,
              whatsapp: null,
              email: null,
              date_of_barth: null,
              profile_link: null,
              camera: [],
              hearing_type: null,
              government: null,
              company_name: null,
              company_phone: null,
              company_address: null,
              company_website: null,
              company_social: null,
              id_card: null,

              area: null,
              street: null,
              building: null,
              floor: null,
              apartment: null,
            }
          } else {
            this.$api.ShowModal('error', response.msg);
          }
        })
      } else {
        this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
      }
    }
  },
  watch: {
    $route() {
      this.setLockups()
    },
    'form.government'() {
      if (this.form.government) {
        this.form.city = null;
      }
    },

  },
  computed: {
    cities() {
      if (this.form.government) {
        let govIndex = this.governments.findIndex(gov => gov.name == this.form.government)
        return cities_json.filter(c => c.governorate_id == govIndex + 1) || []
      } else {
        return cities_json || []
      }
    },
    inputDesign() {
      if (this.$store.state.windowSize.x <= 991) {
        return 'up_label'
      } else {
        return 'inline_label'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.subtitle-1 {
  display: block;
  font-weight: bold;
  border-left: 3px solid var(--primary);
  padding: 0.1rem 0.7rem;
  color: #000;
}

.body-2 {
  line-height: 1.6rem;
}

.v-application--is-rtl {
  .subtitle-1 {
    border-left: 0;
    border-right: 3px solid var(--primary);
  }
}
</style>