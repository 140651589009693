<template>
  <v-card relative class="shadow-none rounded-0 pa-0  d-none d-md-block">
    <v-container class="py-0 pb-1">
      <v-row justify="space-between">
        <v-col cols="auto" class="py-2">
          <v-row align="center">
            <v-col cols="auto" class="py-2">
              <v-btn text plain small link :href="`tel:${$store.state.basic_data.phone}`">{{ $store.state.basic_data.phone
              }}</v-btn>
            </v-col>
            <v-col cols="auto" class="py-2 caption gray6--text">
              {{ $t('welcome to our shop') }} !
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="py-2">
          <v-row>
            <v-col cols="auto" class="py-2">
              <v-btn text plain :to="`/${$i18n.locale}/registration`" small>{{ $t("signup") }}</v-btn>
            </v-col>
            <v-col cols="auto" class="py-2">
              <v-badge :content="wishList.length" :value="wishList.length" color="error" overlap  offset-y="20">
                <v-btn text plain small :to="`/${$i18n.locale}/wishlist`">{{ $t("wishlist") }}</v-btn>
              </v-badge>

            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-col cols="12" class="pt-2 pb-0 px-0">
      <v-divider class="opacity-50" />
    </v-col>
  </v-card>
</template>

<script>
export default {
  name: "FirstBar",
  components: {

  },
  computed: {
    wishList() {
      return this.$store.getters.getAllWishList
    }
  },
  data: () => ({}),
  watch: {},
  mounted() { },
  methods: {},
};
</script>
<style scoped></style>
