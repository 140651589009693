<template>
  <v-card class="flex rounded-0 pa-0" color="#323232" flat>
    <v-container>
      <v-card flat class="pa-7 rounded-0 transparent">
        <v-row justify="center">
          <v-col cols="12" sm="6" md="4" lg="3">
            <h6 class="subtitle-1">{{ $t('departments') }}</h6>
            <router-link :to="`/${$i18n.locale}/products?department=${department.id}`" class="subtitle-2"
              v-for="(department, index) in departments" :key="index">{{ department.name }}</router-link>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <h6 class="subtitle-1">{{ $t('categories') }}</h6>
            <router-link :to="`/${$i18n.locale}/products?category=${category.id}`" class="subtitle-2"
              v-for="(category, index) in categories" :key="index">{{ category.name }}</router-link>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <h6 class="subtitle-1">{{ $t('brands') }}</h6>
            <router-link :to="`/${$i18n.locale}/products?brand=${brand.id}`" class="subtitle-2"
              v-for="(brand, index) in brands" :key="index">{{ brand.name }}</router-link>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <h6 class="subtitle-1">{{ $t('other links') }}</h6>
            <router-link :to="`/${$i18n.locale}/registration`" class="subtitle-2">
              {{ $t('signup') }}</router-link>
            <router-link :to="`/${$i18n.locale}/products`" class="subtitle-2">{{ $t('products') }}</router-link>
            <router-link :to="`/${$i18n.locale}/about`" class="subtitle-2">{{ $t('about us') }}</router-link>
            <router-link :to="`/${$i18n.locale}/contact`" class="subtitle-2">{{ $t('contact us') }}</router-link>
            <router-link :to="`/${$i18n.locale}/privacy`" class="subtitle-2">{{ $t('privacy policy') }}</router-link>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-card-text class="py-3 pb-5 gray3 white--text text-center">
      <v-row justify="space-between" align="center">
        <v-col cols="auto" class="d-flex">
          <a :href="basicData.facebook_link" target="_blank" class="subtitle-2 me-2" v-if="basicData.facebook_link">
            <v-icon color="white">mdi-facebook</v-icon>
          </a>
          <a :href="basicData.instagram_link" target="_blank" class="subtitle-2 me-2" v-if="basicData.instagram_link">
            <v-icon color="white">mdi-instagram</v-icon>
          </a>
          <a :href="`https://wa.me/${basicData.whatsapp}`" target="_blank" class="subtitle-2 me-2"
            v-if="basicData.whatsapp">
            <v-icon color="white">mdi-whatsapp</v-icon>
          </a>
          <a :href="basicData.google_plus_link" target="_blank" class="subtitle-2 me-2" v-if="basicData.google_plus_link">
            <v-icon color="white">mdi-google</v-icon>
          </a>
          <a :href="basicData.linkedin_link" target="_blank" class="subtitle-2 me-2" v-if="basicData.linkedin_link">
            <v-icon color="white">mdi-linkedin</v-icon>
          </a>
          <a :href="basicData.twitter_link" target="_blank" class="subtitle-2 me-2" v-if="basicData.twitter_link">
            <v-icon color="white">mdi-twitter</v-icon>
          </a>
          <a :href="basicData.youtube_link" target="_blank" class="subtitle-2 me-2" v-if="basicData.youtube_link">
            <v-icon color="white">mdi-youtube</v-icon>
          </a>
        </v-col>
        <v-col cols="auto">
          {{ new Date().getFullYear() }} — <strong>PIXIPINE</strong>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FooterSection",
  components: {},
  data: () => ({
    isLoading: false,

  }),
  props: {},
  watch: {},
  computed: {
    departments() {
      return this.$store.getters.homePage.departments || [];
    },
    categories() {
      return this.$store.getters.homePage.categories || [];
    },
    brands() {
      return this.$store.getters.homePage.brands || [];
    },
    basicData() {
      return this.$store.state.basic_data || {};
    },
  },
  mounted() { },
  methods: {},
};
</script>

<style lang="scss" scoped>
.subtitle-1 {
  display: block;
  font-weight: bold;
  border-left: 3px solid var(--primary);
  padding: 0.1rem 0.7rem;
  color: #fff;
}

.v-application--is-rtl {
  .subtitle-1 {
    border-left: 0;
    border-right: 3px solid var(--primary);
  }
}

.subtitle-2 {
  display: block;
  color: #fff;
  opacity: 0.5;
  text-decoration: none;
  transition: 0.3;
  margin-top: 10px;

  &:hover {
    opacity: 1;
    color: var(--primary);
  }
}
</style>
