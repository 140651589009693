<template>
  <div>
    <router-view></router-view>
  </div>
</template>


<script>
export default {
  name: "Website",
  components: {
  },
  mounted() {
  
  },
  data: () => ({
  
  }),
  methods: {

  },

}
</script>
<style scoped>
</style>