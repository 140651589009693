<template>
  <v-card class="pa-10" outlined>
     <v-row justify="center" v-if="!edit">
        <v-col cols="12" md="10" lg="9">
           <v-row justify="center">

              <v-col cols="12" class="d-flex justify-end justify-md-space-between">
                 <h6 class="text-h4 d-none d-md-block">{{ $t('personal information') }}</h6>
                 <v-btn color="primary" depressed @click="edit = true">
                    {{ $t('edit your profile') }}
                 </v-btn>
              </v-col>
              <v-col cols="auto">
                 <img loading="lazy" height="112" width="112" class="rounded-circle" :src="userImage(form.image)"
                    alt="pixipine" />
              </v-col>
              <v-col cols="12">
                 <h6 class="text-h6 font-weight-bold gray5--text">{{ $t('name') }}:
                    <span class="primary--text">{{ form.name }}</span>
                 </h6>
              </v-col>
              <v-col cols="12">
                 <h6 class="text-h6 font-weight-bold gray5--text">{{ $t('email') }}:
                    <span class="primary--text text-lowercase">{{ form.email }}</span>
                 </h6>
              </v-col>
              <v-col cols="12">
                 <h6 class="text-h6 font-weight-bold gray5--text">{{ $t('mobile') }}:
                    <span class="primary--text">{{ form.mobile }}</span>
                 </h6>
              </v-col>
           </v-row>
        </v-col>
     </v-row>
     <v-form ref="form" v-else v-model="valid" lazy-validation>
        <v-row justify="center">
           <v-col cols="12" md="10" lg="9">
              <v-row justify="end">
                 <v-col cols="12">
                    <h6 class="text-h4">{{ $t('personal information') }}</h6>
                 </v-col>
                 <v-col cols="12">
                    <AvatarProfile :img="form.image" :useServerUrl="String(form.image).substring(0, 7) == 'uploads'"
                       :callBackMethod="updateImage" :base64="false" />
                 </v-col>
                 <GenericInput type="text"  name="name" label="name" :dense="false" :value="form.name"
                    :keydownEnter="updateProfile" @input="form.name = $event" :required="true" :isLoading="isLoading"
                    :cols="[12, 12, 12]" />

                 <GenericInput type="email" name="user_email" label="email" :dense="false" :value="form.email"
                    :keydownEnter="updateProfile" @input="form.email = $event" :required="true" :isLoading="isLoading"
                    :cols="[12, 12, 12]" />

                 <GenericInput type="mobile" name="mobile" label="mobile" :dense="false" :value="form.mobile"
                    :keydownEnter="updateProfile" @input="form.mobile = $event" :required="true" :isLoading="isLoading"
                    :cols="[12, 12, 12]" />

                 <GenericInput type="password" name="new_user_password" label="password" :dense="false"
                    :value="form.password" :keydownEnter="updateProfile" @input="form.password = $event" :required="true"
                    :isLoading="isLoading" :cols="[12, 12, 12]" />


                 <v-col cols="12" md="6">
                    <v-btn color="primary" x-large block height="58" @click="updateProfile"
                       depressed :loading="isLoading">{{ $t('update profile') }}</v-btn>
                 </v-col>
              </v-row>
           </v-col>
        </v-row>
     </v-form>
  </v-card>
</template>
 
<script>
import AvatarProfile from '@/components/ui/AvatarProfile.vue';
import GenericInput from '@/components/ui/GenericInput.vue';

export default {
  name: "ProfilePage",
  components: {
     AvatarProfile,
     GenericInput,
  },
  data: () => ({
     valid: false,
     edit: false,
     isLoading: false,
     changePassDialog: false,
     form: {},
  }),
  head() {

  },
  computed: {
     userImage() {
        return (image) => {
           if (image) {
              return String(image).substring(0, 7) == 'uploads' ? this.$store.state.endpointURL + image : image
           } else {
              return require('@/assets/img/svg/user.svg')
           }
        }
     }
  },
  mounted() {
     this.form = this.$global.SelectFromObject(this.$store.getters.user, 'email', 'mobile', 'image', 'name', 'facebook_id', 'google_id');
     this.form.image = this.form.image || null;
     this.form.password = null;
  },
  watch: {
     $route() {
        this.form = this.$global.SelectFromObject(this.$store.getters.user, 'email', 'mobile', 'image', 'name', 'facebook_id', 'google_id');
        this.form.image = this.form.image || null;
        this.form.password = null;
     },
     '$store.getters.user'() {
        this.form = this.$global.SelectFromObject(this.$store.getters.user, 'email', 'mobile', 'image', 'name', 'facebook_id', 'google_id');
        this.form.image = this.form.image || null;
        this.form.password = null;
     }
  },
  methods: {
     updateImage(img) {
        this.form.image = img;
     },
     updateProfile() {
        if (this.valid) {
           console.log(this.form);
           this.isLoading = true;
           this.$api.POST_METHOD_MULTIPART(`update_profile`, this.form)
              .then((response) => {
                 console.log(response);
                 this.isLoading = false;
                 if (response) {
                  this.$api.SET_AUTH(response?.data?.data);
                    this.edit = false;
                 }
              })
        } else {
           this.$store.state.showSnackbar = false;
           this.$store.state.snackbarMessages = [];
           this.$store.state.snackbarTitle = 'check inputs fields';
           this.form.password || this.$store.state.snackbarMessages.push('please enter your password');
           this.$store.state.snackbarType = "error";
           this.$store.state.showSnackbar = true;
        }
     }
  },

}
</script>
 

<style lang="scss" scoped>
.text-h4 {
  font-weight: 600;
  font-size: 24px !important;
  line-height: 36px;
  color: var(--gray2);
}
</style>