<template>
  <div :class="classes" v-if="isLoading">
      <v-progress-circular indeterminate :rotate="360" :size="height" :width="10" color="primary" />
  </div>
</template>


<script>
export default {
  name: "AnimatedLoading",
  props: {
    height: { default: 100 },
    isLoading: { default: false},
    hideText: { default: false},
    classes: { default: "d-flex justify-center my-16" },
  },
  data: () => ({

  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss">

</style>
