<template>
  <section>
    <HeroSection :slides="homeData?.sliders || []" v-if="homeData?.sliders.length" />
    <v-container class="pa-6 mb-5" v-if="homeData?.ads.length" fluid>
      <v-row>
        <v-col cols="12" md="6" lg="4" class="py-2" v-for="i in 3" :key="i">
          <v-card flat class="bannerCard" :to="homeData?.ads.at(i - 1).link">
            <v-img cover :aspect-ratio="16 / 7" width="100%" class="d-block" style="max-height: 250px;"
              :src="$store.state.endpointURL + homeData?.ads.at(i - 1).image" alt="" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ProductsRowSection :title="$t('featured products')" show_route="products/" :groups="homeData?.featuredProducts"
      :type="1" v-if="homeData?.featuredProducts.length" />

    <ProductsSliderSection show_route="products/" :sliders="homeData?.sliders_collections" :type="1"
      v-if="homeData?.sliders_collections?.length" />

    <!--  <BlogsRowSection title="from the blog" show_route="blogs/all-categories" :blogs="highBlogs" v-if="highBlogs.length" />
    <NewsLetterSection :isLoading="false" /> -->

    <ProductsRowSection :title="homeData?.large_product_section[0]?.name" show_route="products/"
      :products="homeData?.large_product_section[0]?.products" :type="2" v-if="homeData?.large_product_section?.length" />


    <v-container class="py-0 mb-5" v-if="homeData?.ads.length">
      <v-row justify="center">
        <v-col cols="12" md="6" class="py-2" v-for="i in 2" :key="i">
          <v-card flat class="bannerCard" :to="homeData?.ads.at(i).link">
            <v-img cover :aspect-ratio="16 / 7" width="100%" :src="$store.state.endpointURL + homeData?.ads.at(i).image"
              alt="" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <ProductsRowSection v-for="(collection, index) in homeData?.other_collections " :key="index" :title="collection.name"
      show_route="products/" :products="collection.products" :type="3" />
  </section>
</template>


<script>
import HeroSection from '@/components/ui/HeroSection.vue';
import ProductsRowSection from '@/components/ui/ProductsRowSection.vue';
import ProductsSliderSection from '@/components/ui/ProductsSliderSection.vue';
export default {
  name: "HomeModule",
  components: {
    HeroSection,
    ProductsRowSection,
    ProductsSliderSection,
  },
  data: () => ({


  }),
  watch: {

  },
  created() {

  },
  destroyed() {

  },
  computed: {
    homeData() {
      return this.$store.getters.homePage
    },

  },
  mounted() {
  },
  methods: {

  }

};
</script>


<style lang="scss" scoped></style>