<template>
  <v-container style="height: 50vh" class="d-flex align-center justify-center bgError">
    <div>
      <v-row align="center">
        <v-col cols="12">
          <h1 class="text-h1 primary--text text-center  font-weight-bold">404</h1>
        </v-col>
        <v-col cols="12">
          <h1 class="text-center">{{ $t('Page not Found') }}</h1>

        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.bgError {
  //background-image: url("../../assets/img/svg/errorBg.svg");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  img {
    margin: auto;
    display: block;
    max-height: 200px;
  }
}
</style>

<script>
export default {
  name: "Error404",
  computed: {},
  mounted() {
  },
};
</script>
